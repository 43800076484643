.body {
  width: 100%;
  // min-height: 60vh;
}
.header {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.headerText {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
headerText3{
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center; 
}

.button {
  padding: 10px 15px;
  background: #990f0d;
  text-align: center;
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: bold;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;

  .page {
    font-weight: bold;
    margin: 10px 0;
  }
}
.headerText2 {
  font-weight: bold;
  font-size: 1.4rem;
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-start;
}
#reportCardTemp {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#reportCardPdf {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 500px) {
  .headerText{
    font-size: 0.9rem;
  }
  .headerText3{
    font-size: 0.9rem;
  }
  .headerText2{
    font-size: 1rem;
    justify-content: initial;
  }
  .reportCardDiv{
    width: 100%;
    overflow-x: auto;
  }
}
.HoverName:hover{
color: blue;
}