#reportCard {
  display: flex;
  align-items: center;
  justify-content: center;
}

#reportCardPdf {
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  width: 100%;
  //   min-height: 60vh;

  .header {
    display: flex;
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .indicator {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        font-size: 1rem;
        font-weight: 700;
        margin: 0 5px;
        span {
          font-size: 1rem;
          font-weight: 700;
          margin: 0 5px;
        }
      }
      .progress {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid black;
        background: yellow;
      }
      .completed {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid black;
        background: #7cfc00;
      }
      .empty {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid black;
        background: red;
      }
    }
  }
  .tableWrapper {
    // width: 100%;
    border: 1px solid #ccc;
    // overflow-y: scroll;
    height: 100%;
    width: 100%;
    max-height: 38vh;
    min-height: 25vh;
    overflow: auto;

    table {
      width: 100%;
      height: 100%;
      border: 2px solid black;
      border-collapse: collapse;
      text-align: center;

      input {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        text-align: center;
      }

      tr {
        border: 1px solid black;
        th {
          border: 1px solid black;
          padding: 10px;
          font-size: 1rem;
          font-weight: bold;
          background: #990f0d;
          color: white;
        }
        td {
          padding: 10px;
          border: 1px solid black;
        }
      }
    }
  }

  .buttonWrapper {
    margin: 20px 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .buttonSubWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 50%;
    }

    .button {
      width: 180px;
      height: 50px;
      background: #990f0d;
      color: white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      margin: 0 50px;
    }
  }
}
.Subheader {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0;
}
.headTitle {
  font-size: 1.3rem;
  font-weight: bold;
}

.warning {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  .caution {
    width: 15px;
    height: 15px;
  }
  p {
    margin-left: 5px;
    font-size: 0.8rem;
  }
}

.WarCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 20px;
  width: 200px;

  .WarRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0.5rem 0;
  }
}
@media (max-width: 900px) {
  .body {
    width: 100%;
    //   min-height: 60vh;
  
    .header {
      display: flex;
      margin-bottom: 1rem;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
  
      .indicator {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
  
        p {
          font-size: 1rem;
          font-weight: 700;
          margin: 0 5px;
          span {
            font-size: 1rem;
            font-weight: 700;
            margin: 0 5px;
          }
        }
        .progress {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background: yellow;
        }
        .completed {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background: #7cfc00;
        }
        .empty {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background: red;
        }
      }
    }
    .tableWrapper {
      // width: 100%;
      border: 1px solid #ccc;
      // overflow-y: scroll;
      height: 100%;
      width: 100%;
      max-height: 38vh;
      min-height: 25vh;
      overflow: auto;
  
      table {
        width: 100%;
        height: 100%;
        border: 2px solid black;
        border-collapse: collapse;
        text-align: center;
  
        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          text-align: center;
        }
  
        tr {
          border: 1px solid black;
          th {
            border: 1px solid black;
            padding: 10px;
            font-size: 1rem;
            font-weight: bold;
            background: #990f0d;
            color: white;
          }
          td {
            padding: 7px !important;
            border: 1px solid black;
          }
        }
      }
    }
  
    .buttonWrapper {
      margin: 20px 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .buttonSubWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 50%;
      }
  
      .button {
        width: 180px;
        height: 50px;
        background: #990f0d;
        color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin: 0 10px;
      }
    }
  }
  .WarCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 5px;
    width: 200px;
  
    .WarRow {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0.5rem 0;
    }
  }
}
@media (max-width: 550px) {
  .body {
    width: 100%;
    //   min-height: 60vh;
  
    .header {
      display: flex;
      margin-bottom: 1rem;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .indicator {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
  
        p {
          font-size: 1rem;
          font-weight: 700;
          margin: 0 5px;
          span {
            font-size: 1rem;
            font-weight: 700;
            margin: 0 5px;
          }
        }
        .progress {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background: yellow;
        }
        .completed {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background: #7cfc00;
        }
        .empty {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid black;
          background: red;
        }
      }
    }
    .tableWrapper {
      // width: 100%;
      border: 1px solid #ccc;
      // overflow-y: scroll;
      height: 100%;
      width: 100%;
      max-height: 38vh;
      min-height: 25vh;
      overflow: auto;
  
      table {
        width: 100%;
        height: 100%;
        border: 2px solid black;
        border-collapse: collapse;
        text-align: center;
  
        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          text-align: center;
        }
  
        tr {
          border: 1px solid black;
          th {
            border: 1px solid black;
            padding: 10px;
            font-size: 1rem;
            font-weight: bold;
            background: #990f0d;
            color: white;
          }
          td {
            padding: 4px;
            border: 1px solid black;
          }
        }
      }
    }
  
    .buttonWrapper {
      margin: 20px 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
  
      .buttonSubWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
      }
  
      .button {
        width: 180px;
        height: 50px;
        background: #990f0d;
        color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin: 0 10px;
      }
    }
  }
  .WarCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 5px;
    width: 200px;
  
    .WarRow {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0.5rem 0;
    }
  }
  .warning {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    .caution {
      width: 15px;
      height: 15px;
    }
    p {
      margin-left: 5px;
      font-size: 0.8rem;
    }
  }
  .dropDown{
    width: 50%;
  }
}