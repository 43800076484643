.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea,
  input:not(.fileInput) {
    background: white;
    border: 1.5px solid #dcdfe3;
    padding-left: 8px;
    border-radius: 4px;
  }
}
.heading {
  font-weight: bold;
  font-size: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 10px;
  margin: 5px 0;
}
.forms {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile {
  width: 100%;
  margin: 0 auto;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .profileImage {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 1px solid black;
  }
}

.profiles {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.form {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  .placeholder {
  }
  .textInput {
    width: 200px;
    height: 30px;
  }
  p {
    padding-bottom: 10px;
    font-weight: 700;
  }
}

.formDate {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border: 2px solid red;
  .placeholder {
  }
}

.chooseImage {
  margin-top: 10px;
  // width: 100%;
}

.fileInput {
  margin-left: 100px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 50px 0;
  .button {
    width: 100px;
    height: 40px;
    background: #990f0d;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
}

.formc {
  display: flex;
  align-items: flex-start;
  gap: 10rem;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  .placeholder {
  }
  .textInput {
    width: 200px;
    height: 30px;
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.optionLists {
  width: 30px !important;
  padding: 0px !important;
  appearance: none;
  margin-right: 5px;
  border: 1px solid black;
}

@media (max-width: 950px) {
  .row {
    flex-wrap: wrap;
    margin: 0px;
  }
  .form {
    width: 295px;
    margin: 10px;
  }
  .formc {
    gap: 3rem;
  }
}
@media (max-width: 500px) {
  .formc {
    gap: 0rem;
    display: flex;
    flex-direction: column;
  }
  .formc label {
    margin-bottom: 5px;
  }
  .form {
    // border-radius: 20px;
    .placeholder {
    }
    .textInput {
      width: 100%;
      height: 40px;
    }
  }
}
.asterisk {
  color: red;
  font-size: 20px;
}
.datePicker::placeholder {
  color: #a8bad0;
}
