@import "../../global.scss";
@import "../../Styles/color.css";
.wrapper {
  width: 100%;
  padding: 25px 0;
  background: var(--PrimaryColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-left-radius: 100px;
  @include mobile {
    padding: 25px;
    max-height: 350px;
    border-bottom-left-radius: 85px;
  }
}
.headerDiv {
  width: 100%;
  background-color: var(--SecondaryColor);
  position: sticky;
  top: 0;
  z-index: 999;
}
.iconDiv {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #ffda76;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
  @include mobile {
    width: 150px;
    height: 100px;
    margin-left: 20px;
  }
}

.icon {
  width: 130px;
  height: 130px;
  @include mobile {
    width: 500px;
    height: 100px;
  }
}
.textDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  color: #ffffff;
  padding-left: 50px;
  @include mobile {
    padding-left: 10px;
  }
}
.header {
  margin: 5px 0;
  font-size: 30px;
  font-weight: 900;
  @include mobile {
    font-size: 20px;
    margin: 0px;
  }
}
.subTitle {
  margin: 1px;
  font-size: 20px;
  @include mobile {
    font-size: 10px;
  }
}
.backIcon {
  position: fixed;
  color: #ffda76 !important;
  font-size: 40px;
  top: 80px;
  left: 20px;
  cursor: pointer;
  @include mobile {
    top: 55px;
    left: 1px;
  }
}
.containerBox {
  height: 20vw;
  overflow-y: auto;
}
.list {
  width: 92%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  margin: 15px;
  .profile {
    .profileImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      border: 1px solid black;
    }
  }
  .info {
    .name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 2px;
    }
    .class {
      font-size: 14px;
      margin-bottom: 2px;
    }
    .number {
      font-size: 14px;
    }
  }
}
.profile {
  .profileImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid black;
  }
}
@media (max-width: 920px) {
  .icon {
    width: 115px;
    height: 115px;
  }
  .header {
    font-size: 25px;
  }
  .subTitle {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .iconDiv {
    width: 85px;
    height: 80px;
    margin-left: 37px !important;
  }
  .icon {
    width: 80px;
    height: 75px;
  }
  .wrapper {
    padding: 12px !important;
    border-bottom-left-radius: 64px;
  }
  .header {
    font-size: 13px;
  }
  .subTitle {
    font-size: 8px;
  }
  .backIcon {
    width: 7vw !important;
    height: 7vw !important;
    top: 40px;
  }
  .textDiv {
    padding-left: 7px;
  }
}
.poweredtext {
  font-size: 30px;
  display: flex;
  align-items: end;
  justify-content: end;
  color: #ffffff;
  width: 98%;
}
.wrappers {
  width: 100%;
  padding: 12.5px 0;
  background: var(--PrimaryColor);
  display: flex;
  flex-direction: column;
  // align-items: center;
  border-bottom-left-radius: 100px;
  @include mobile {
    padding: 12.5px;
    max-height: 350px;
    border-bottom-left-radius: 85px;
  }
}
