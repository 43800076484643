.wrapper {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.stats {
  font-weight: bold;
  font-size: 20px;
  margin: 0 5px;
}
.body {
  margin: 35px 0;
}
.lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}
.list {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 120px;
}
.listWithoutRead {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 120px;
  background: #eee;
}
.content:hover {
  color: blue;
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  flex: 0.9;
  color: black;
}
.fontSize {
  font-weight: 700;
  font-size: 17px;
}
.date {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
