.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}

.body {
  margin: 50px 0;
}

.lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}
.icon {
  font-size: 30px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: #990f0d 0px 2px 8px 0px;
}
.list {
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 120px;
}
.list:hover {
  .icon {
    box-shadow: black 0px 2px 8px 0px;
    color: black;
  }
  box-shadow: #990f0d 0px 2px 8px 0px;
}
.content:hover {
  color: blue;
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  flex: 0.8;
  color: black;
}
.eyeIcon {
  font-size: 20px;
  margin-right: 10px;
}

.views {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  font-style: italic;
}
.date {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}
.profileImage {
  width: 200px;
  height: 150px;
}

.name {
  margin: 5px 0;
  // text-align: center;
}

.class {
  margin: 5px 0 10px 0;
}

.addButton {
  cursor: pointer;
  // border: 2px solid red;
  // margin: 0 10px;
  .add {
    width: 50px;
    height: 50px;
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 3px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 35px;
        height: 35px;
      }
    }
    .migrateButton {
      cursor: pointer;
      // border: 2px solid red;
      .migrate {
        font-size: 28px;
        margin-left: 15px;
      }
    }
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.readMoreButton {
  text-align: center;
}
@media (max-width: 950px) {
  .header {
    width: 50%;
    margin-top: 10px;
  }
  .stats {
    font-size: 17px !important;
  }
  .list {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
@media (max-width: 500px) {
  .header {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
  .headerFirst {
    flex-direction: column;
    align-items: flex-start;
  }
  .list {
    width: 280px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .fontSize {
    font-size: 0.8rem;
    font-weight: 600;
  }
}
