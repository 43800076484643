.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.filterWrapper {
  margin: 20px;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  button {
    width: 100px;
    align-self: center;
    margin-top: 20px;
  }
}
.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.addButton {
  cursor: pointer;
  // border: 2px solid red;
  margin: 0 10px;
  .add {
    width: 50px;
    height: 50px;
  }
}

.filterWrapper {
  margin: 0 10px;
}
.body {
  margin: 50px 0;

  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    // border: 2px solid red;

    .list {
      width: 30%;
      color: black;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
      margin: 10px 0;

      a {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
      }

      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
          font-weight: bold;
        }
      }
    }
  }
}

.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;

      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;

      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}
.body {
  margin: 50px 0;

  .editImage {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .editbox {
    display: flex;
    flex-direction: row;
    // align-items: right;
    padding-top: 10px;
    margin-left: 5px;
    gap: 15px;
  }

  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    // border: 2px solid red;
    .list {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
      margin: 10px 0;

      .profile {
        .profileImg {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
        }
      }

      .info {
        .name {
          font-size: 18px;
          margin-bottom: 2px;
          padding: 2px;
        }

        .class {
          font-size: 18px;
          margin-bottom: 2px;
          padding: 2px;
          font-weight: bold;
        }

        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
