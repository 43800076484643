.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}
.staffCount {
  display: flex;
  width: 80%;
}
.body {
  margin: 30px 0;
  .lists {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-evenly;
    // flex-wrap: wrap;
    // // border: 2px solid red;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .list {
      // width: 100%;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // // justify-content: space-between;
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      // padding: 20px;
      // margin: 10px 0;
      // gap: 1rem;

      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      padding: 20px;
      margin: 10px 0;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
          // width: 200px;
          // display: -webkit-box;
          // -webkit-line-clamp: 1;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: normal;
          // height: 25px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}
.stats {
  font-weight: bold;
  font-size: 20px;
  margin: 0 10px;
}
.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: 800;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 60px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 50px;
        height: 50px;
      }
    }
  }
}
.fileFormatContainer {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
}
@media (max-width: 950px) {
  .header {
    width: 105%;
  }
  .stats {
    font-size: 16px !important;
  }
  .add {
    width: 8vw !important;
    height: 4vw !important;
  }
  .download {
    width: 8vw !important;
    height: 4vw !important;
  }
  .list {
    width: 44% !important;
  }
  .profile {
    margin-right: 1vw;
  }
  .name {
    font-size: 15px !important;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
  font-weight: bold;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 2px;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
}
@media (max-width: 550px) {
  .body{
    margin: 25px 0;
  }
  .headerFirst {
    flex-direction: column;
  }
  .filterWrapper {
    margin: 0 !important;
    margin-right: 3px !important;
    width: 43%;
    margin-bottom: 5px !important;
  }
  .add {
    width: 13vw !important;
    height: 7vw !important;
  }
  .download {
    width: 13vw !important;
    height: 7vw !important;
  }
  .header {
    margin-bottom: 2vw !important;
    flex-wrap: wrap;
  }
  .list {
    width: 90% !important;
    padding: 14px !important;
  }
  .name {
    font-size: 13px !important;
    // width: 170px !important;
    // height: auto !important;
  }
  .class {
    font-size: 12px !important;
  }
  .number {
    font-size: 12px !important;
  }
  .staffCount {
    width: 100%;
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}

  