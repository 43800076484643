.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea,
  input:not(.fileInput) {
    background: white;
    border: 1.5px solid #dcdfe3;
    padding-left: 8px;
    border-radius: 4px;
  }
}

.forms {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 10px;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  margin: 5px 0;
}
.icons {
  font-size: 20;
  margin-left: 8px;
  cursor: pointer;
}
.profile {
  width: 50%;
  margin: 0 auto;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .profileImage {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 1px solid black;
  }
}
.rowHeading {
  display: flex;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap ;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.form {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  .placeholder {
  }
  .textInput {
    width: 200px;
    height: 30px;
  }
  p {
    padding-bottom: 10px;
    font-weight: 700;
  }
}
.formDate {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border: 2px solid red;
  .placeholder {
  }
}

.chooseImage {
  margin-top: 10px;
  // width: 100%;
}

.fileInput {
  margin-left: 100px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 50px 0;
  gap: 16px;
  .button {
    width: 100px;
    height: 40px;
    background: #990f0d;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
}
.button2 {
  width: 100px;
  height: 40px;
  background: #228b15;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.optionLists {
  width: 30px !important;
  padding: 0px !important;
  appearance: none;
  margin-right: 5px;
  border: 1px solid black;
}
@media (max-width: 950px) {
  .row {
    flex-wrap: wrap;
    margin: 0px;
  }
  .form {
    width: 295px;
    margin: 10px;
  }
  .formc {
    gap: 3rem;
  }
}
@media (max-width: 500px) {
  .formc {
    gap: 0rem;
    display: flex;
    flex-direction: column;
  }
  .formc label {
    margin-bottom: 5px;
  }
  .profileImage {
    width: 125px !important;
    height: 125px !important;
  }
  .form {
    .placeholder {
    }
    .textInput {
      width: 100%;
      height: 40px;
    }
  }
  .formClassConatiner {
    width: 300px !important;
    padding: 10px !important;
    margin: 10px !important;
  }
}
.asterisk {
  color: red;
  font-size: 20px;
}
.datePicker::placeholder {
  color: #a8bad0;
}
.formClassConatiner {
  width: 400px;
  display: flex;
  margin: 0 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  border-radius: 5px;
  justify-content: space-evenly;
}
.classTeacher {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 20px;
  margin: 10px 0;
}
.handleClassList {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 20px;
  margin: 10px 0;
  width: 45%;
}
.handleClasses {
  display: flex;
  flex-direction: column;
}
.customModalContent {
  max-width: 600px !important;
  width: 100% !important;
}
