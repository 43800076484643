.body {
  width: 100%;
  // min-height: 60vh;
  position: relative;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

.subWrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}

.add {
  cursor: pointer;
  .addImage {
    width: 45px;
    height: 45px;
    margin: 5px;
  }
}

.lists {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.list {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 40px;
  margin: 20px auto;
}

.listItem {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.listItemText {
  font-size: 1.2rem;
  font-weight: bold;
}

.listItemVal {
  font-size: 1.2rem;
}

.formGroup {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  .formLabel {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1px;
  }
  .formInput {
    border: 1px solid black;
    border-radius: 0;
    padding: 5px;
    // width: 100%;
    width: 90%;
    text-indent: 10px;
    margin: 5px;
    margin-right: 0;
    margin-left: 5px;
  }
}
.formGroupRoute {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.routeNo {
  width: 80%;
  margin: 0 auto;
}
.formLabel {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1px;
}
.formInput {
  border: 1px solid black;
  border-radius: 0;
  padding: 5px;
  width: 100%;
  width: 90%;
  text-indent: 10px;
  margin: 5px;
  margin-right: 0;
  margin-left: 5px;
}
.formButton {
  width: 150px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: green;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 25px 30px 25px;
}

.formRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  // flex-wrap: wrap;
}
.busDetailsTime {
  max-height: 250px;
  overflow-y: scroll;
}
.formCol {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-right: 10px;
}

.buttonRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.delete {
  cursor: pointer;
  .deleteImage {
    width: 35px;
    height: 35px;
  }
}

.directionRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.directionCol {
  // width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  // margin: 0 10px;
}

.direction {
  width: 30px;
  height: 30px;
  margin: 0 50px;
}
.filterWrapper {
  margin-right: 5px;
}
.headerOption {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.footer {
  position: fixed;
  bottom: 10px;
  width: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: white;
  padding: 20px 0;
  .footerText {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .footerButton {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding-bottom: 5px;
  }
}

.select {
  margin-bottom: 15px;
}

.edit {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 90%;
  .editImage {
    margin-left: 10px;
    width: 25px;
    height: 25px;
  }
}

.disabled {
  opacity: 0.6;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

.note {
  border: 2px solid #000;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding: 5px;
  width: 50%;
}

.clearIcon {
  cursor: pointer;
}

.downloadBtn {
  background-color: #990f0d;
  margin-left: 1vw;
  padding: 8px;
  text-align: center;
  margin-bottom: 15px;
  color: white;
  font-size: 1.05rem;
  font-weight: 600;
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.asterisk {
  color: red;
  font-size: 20px;
}
.enrolledLists {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  .enrolledList {
    width: 30%;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f8cdd44f;
    padding: 10px;
    margin: 10px 0;
    .profile {
      .profileImg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid black;
      }
    }
    .info {
      .name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2px;
      }
      .class {
        font-size: 14px;
        margin-bottom: 2px;
      }
      .number {
        font-size: 14px;
        // margin-bottom: 2px;
      }
    }
  }
}
@media (max-width: 950px) {
  .list {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px 30px;
    margin: 20px auto;
  }
  .edit {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    .editImage {
      margin-left: 10px;
      width: 25px;
      height: 25px;
    }
  }
}
@media (max-width: 550px) {
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px 10px;
    margin: 20px auto;
  }
  .title {
    font-size: 1rem;
  }
  .add {
    cursor: pointer;
    .addImage {
      width: 35px;
      height: 35px;
      margin: 5px;
    }
  }
  .listItemText {
    font-size: 1rem;
  }
  .listItemVal {
    font-size: 1rem;
  }
}
.customTimepickerInput {
  width: 100%;
  height: 35px;
}
@media (max-width: 500px) {
  .headerFirst {
    flex-direction: column;
  }
  .headerOption {
    flex-wrap: wrap;
    justify-content: center;
  }
  .filterWrapper {
    margin-right: 8px !important;
    margin-top: 5px;
    width: 45%;
  }
  .add {
    // width: 16vw !important;
    height: 7vw !important;
  }
  .download {
    width: 13vw !important;
    height: 7vw !important;
  }
  .header {
    margin-bottom: 2vw !important;
    flex-wrap: wrap;
  }
  .listing {
    width: 90% !important;
    padding: 14px !important;
  }
  .name {
    font-size: 13px !important;
    // width: 170px !important;
    // height: auto !important;
  }
  .class {
    font-size: 12px !important;
  }
  .number {
    font-size: 12px !important;
  }
  .stats {
    font-size: 13px !important;
  }
  .filterButton {
    padding: 5px !important;
    font-size: 15px !important;
  }
  .enrolledList {
    width: 100% !important;
  }
  .title {
    margin-left: 0rem;
    margin-right: 1rem;
  }
}
.mobileselectionContainer {
  // margin-left: 20px;
  // overflow-y: scroll;
  // height: 330px;
  // background: rgb(232, 244, 252);
  padding: 10px;
  border-radius: 10px;
  // width: 55%;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}
.mobileformatContainer { 
  //  background: rgb(255, 244, 172);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}
.mobilerow {
 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
  font-weight: bold;

}
.mobilemoduleIcon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 10px;
}
.mobilemoduleName {
  font-weight: bold;
  font-size: 20px;
}
.mobilefilterWrapper {
  margin-top: 5px;
  width: 45%;
}
.mobileheading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  // width: 98%;
}
.mobilecriteriaText {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 3px;
}
.mobilefilterGrade {
  font-weight: 500;
  font-size: 17px;
  margin-right: 25px;
}
.mobilebtn {
  font-weight: 700;
  font-size: 18px;
  padding: 15px;
  margin-right: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
}
.mobilefileFormatContainer {
  display: flex;
  font-weight: 500;
}