.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
}

.category {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 15px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.heading {
  font-size: 30px;
  color: #990f0d;
  font-weight: bold;
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin: 0 10px;
  }
}
