.heading {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.updateHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profileModal {
  display: flex;
  align-items: center;
  width: 37%;
  height: 100px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding-left: 7px;
}
.modalProfileImg {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 3vw;
  border: 1px solid black;
}
.studentName {
  font-size: 1.1rem;
}
.absentHoverContainer {
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 20%;
  padding: 15px;
  width: 300px;
  z-index: 9;
  max-height: 300px;
  overflow-y: scroll;
}
.halfDayHoverContainer {
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 75%;
  padding: 15px;
  width: 300px;
  z-index: 9;
  max-height: 300px;
  overflow-y: scroll;
}
.modalCalendar {
  overflow-y: auto;
  width: 35%;
  max-height: 350px;
  border: 2px solid black;
}
.calendaDataContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.selected {
  appearance: none;
  background-color: #fafafa;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.25;
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 5px;
}

.selected:focus {
  border: 2px solid lightgray;
  border-radius: 5px;
}
.todayButton {
  background-color: #cd3dcd;
  color: white;
  padding: 6px;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 10px;
}
.percentageContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.percentageSubContainer {
  width: 50%;
  font-weight: bold;
  font-size: 18px;
}
.percentageText {
  font-weight: bold;
  font-size: 18px;
}
.percentDigit {
  padding: 5px;
  color: white;
  font-weight: bold;
  border-radius: 20px;
}
.sessionContainer {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;
  padding: 8px;
}
.sessionSubContainer {
  width: 100%;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
}
.monthDataContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;
  padding: 8px;
  font-weight: bold;
}
.calendarMain {
  margin-top: 10px;
  display: flex;
}
.dateContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 8px;
  font-weight: bold;
  margin-left: 20px;
  height: 220px;
  font-size: 16px;
}
.noDate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: rgb(234, 60, 83);
  color: white;
  padding: 10px;
  font-weight: bold;
}
.presentContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 15px;
  padding: 8px;
  font-weight: bold;
  margin-left: 20px;
}
.arrows {
  font-size: 30px;
}
.calendarHeaderMonth {
  padding-left: 20px;
  font-size: 1.5em;
}
.calendarHeaderYear {
  padding-left: 8px;
  font-size: 1.5em;
}
.headerValSel {
  font-size: 1.5em;
}
.headerVal {
  font-size: 1em;
}
.leftContainer {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.attendanceData {
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;
  margin-right: 20px;
  padding: 10px;
  font-weight: bold;
}
@media (max-width: 500px) {
  .updateHeadingContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .profileModal {
    width: 100%;
    margin-bottom: 8px;
  }
  .percentageContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .percentageSubContainer {
    width: 100%;
  }
  .percentageText {
    font-weight: 600;
    font-size: 15px;
  }
  .percentDigit {
    padding: 2px;
    font-weight: 600;
  }
  .forMarginBottom {
    margin-bottom: 4px;
  }
  .sessionContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .sessionSubContainer {
    flex-direction: column;
  }
  .monthDataContainer {
    flex-direction: column;
  }
  .calendarMain {
    flex-direction: column;
  }
  .modalCalendar {
    width: 95%;
    margin-top: 10px;
  }
  .calendaDataContainer {
    width: 100%;
  }
  .dateContainer {
    margin-left: 0px;
    margin-top: 15px;
    height: auto;
  }
  .noDate {
    margin-top: 0px;
  }
  .presentContainer {
    margin-left: 0px;
  }
  .absentHoverContainer {
    right: 10%;
  }
  .halfDayHoverContainer {
    left: 10%;
  }
  .todayButton {
    padding: 4px;
    border-radius: 5px;
    font-weight: 600;
    margin-right: 5px;
  }
  .arrows {
    font-size: 20px;
  }
  .calendarHeaderYear {
    padding-left: 4px;
    font-size: 1em;
  }
  .headerVal {
    font-size: 0.8em;
  }
  .headerValSel {
    font-size: 0.8em;
  }
  .calendarHeaderMonth {
    padding-left: 10px;
    font-size: 1em;
  }
  .heading {
    font-size: 1rem;
    font-weight: 700;
  }
  .studentName {
    font-size: 17px !important;
  }
  .leftContainer {
    width: 100%;
  }
  .attendanceData {
    flex-direction: column;
  }
}
