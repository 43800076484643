.backgroundUrl{
    // width: 50%;
    // height: 100%;
    // background-size: cover; 
    // background-image: url('../../../assets/logos/SMU_Watermark.png');
    background-size:350px 350px;
    // background-color: rgb(254, 250, 250);
    // background-color: #cccccc;
    // background-image: url('../../assets/bg/school.jpg');
    background-repeat: no-repeat;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background-attachment: fixed;
    background-position: center ;
    // background-color: rgb(145, 143, 143);
    // background-color: #f7d5d5; /* Tint color */
    // background-blend-mode: multiply;
    // background-color: aqua;
}