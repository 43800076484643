.body {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url('../../assets/bg/smu.jpg');
    background-image: url('../../assets/bg/SMU_wide.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    // background-color: #b16d6b;
    background-color: #bf5f5c;
    background-size: cover; 
    /* Tint color */
    background-blend-mode: multiply;
}

.logo {
    width: 13%;
    height: 13%;
}