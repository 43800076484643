.body {
  padding: 20px;
  font-weight: 620;
  background-color: #eee;
}
.componentBody{
  display: flex;
  box-shadow: 4px 4px 4px 4px #ccc;
  padding: 20px;
  padding-left: 25px;
  padding-right: 25px;
  width: 80.5%;
  justify-content: space-between;
  // align-items: center;
  flex-direction: column;
  margin-bottom: 1vw;
  border-radius: 5px;
  // flex-direction: column;
}
.componentBody2{
  display: flex;
  box-shadow: 4px 4px 4px 4px #ccc;
  padding: 25px;
  padding-left: 25px;
  padding-right: 25px;
  width: 80.5%;
  justify-content: space-between;
  // align-items: center;
  flex-direction: column;
  margin-bottom: 1vw;
  border-radius: 5px;
}
.containerData{
  display: flex;
  flex-direction: column;
}
.imageSize{
  // height: 3.5vw;
  font-size: 1.2rem;
  font-weight: 500;
  // width: 3.5vw;
}
.Description{
  font-size: 1.1rem;
}
.heading{
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1vw;
}
.leftSection{
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.rightSection{
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mainContainer{
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.NameBox{
  width: 100%;
  display: flex;
  justify-content: space-between;
}