.body {
  margin: 10px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    // border: 2px solid red;
    .list {
      width: 25%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
      margin: 10px 0;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .email {
          font-size: 14px;
          margin-bottom: 2px;
        }
      }
    }
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
  .button {
    width: 100px;
    height: 40px;
    background: teal;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
}

.add {
  cursor: pointer;
  .addImage {
    width: 35px;
    height: 35px;
  }
}

.formGroup {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  .formLabel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;
  }
  .formInput {
    border: 1px solid #e7ecf2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-indent: 10px;
    margin-bottom: 15px;
  }
}

.formButton {
  width: 250px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: green;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 25px 30px 25px;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin: 0 10px;
  }
}
@media (max-width: 550px) {
  .body {
    margin: 10px 0;
    .lists {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      // border: 2px solid red;
      .list {
        width: 45%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 16px;
        margin: 10px 0;
        .profile {
          .profileImg {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
          }
        }
        .info {
          .name {
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 2px;
          }
          .email {
            font-size: 9px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}