@import "../../Styles/color.css";
.body {
  width: 100%;
  margin-top: 20px;
}

.cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.filtercards {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.card {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  margin: 10px;
  padding: 50px 0;
  box-shadow: var(--BoxShadow);
  transition: all 0.1s ease-in-out;

  &:hover {
    scale: 1.1;
    box-shadow: #990f0d 0px 2px 8px 0px;
  }
}
.icon {
  width: 50px;
  margin-bottom: 10px;
}

.title {
  margin: 10px 0;
  color: var(--dashBoardModuleName);
}
@media (max-width: 500px) {
  .card {
    width: 130px;
    padding: 20px;
  }
  .header {
    font-size: 25px;
  }
  .subTitle {
    font-size: 16px;
  }
  .title {
    font-size: 10px;
  }
}
.highlighted {
  background-color: rgba(248, 205, 212, 0.3098039216);
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
