.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}

.body {
  margin: 50px 0;
}

.lists {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.list {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  min-height: 260px;
}
.list:hover {
  box-shadow: #990f0d 0px 2px 8px 0px;
}
.profileImage {
  width: 230px;
  height: 180px;
  border: 2px solid black;
}
.name {
  margin: 5px 0;
  color: #990f0d;
  text-align: center;
  width: 220px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 50px;
}
.headerFirst {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 10px;
    }
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.readMoreButton {
  text-align: center;
}
@media (max-width: 950px) {
  .header {
    width: 50%;
    margin-top: 10px;
  }
  .stats {
    font-size: 17px !important;
  }
}
@media (max-width: 500px) {
  .header {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
  .headerFirst {
    flex-direction: column;
    align-items: flex-start;
  }
}
