@import '../../global.scss';
.body {
    // min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-image: url('../../assets/bg/smu.jpg');
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-position: center top;
    // background-color: #b16d6b;
    // background-color: #bf5f5c;
    // background-size: cover; 
    /* Tint color */
    // background-blend-mode: multiply;
}

.logo {
    width: 13%;
    height: 13%;
}
.container{
    display: flex;
    align-items: center;
    justify-content: space-evenly   ;
    flex-direction: column;
    box-shadow: 5px 5px 5px 5px #888888;
    height: 180px;
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    @include mobile{
        width: 310px;
        height: 150px;
    }

}
.content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

}
.buttons{
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
.actionHeading{
font-size: 20px;
font-weight: bold;
margin-bottom: 10px;
align-self: flex-start;

}
.actionPhrase{
    margin-bottom: 10px;
    align-self: flex-start;
    
    }

   