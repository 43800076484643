.body {
  width: 100%;
  min-height: 65vh;
  position: relative;

  ::-webkit-scrollbar {
    height: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }
}

.scroll-container {
  overflow-x: scroll;
}

.cards {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 20px;

  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: inline-block;
    text-align: center;
    width: 300px;
    background: white;
    min-height: 55vh;
    border-radius: 10px;
    padding: 20px 0;
    margin: 5px 10px;

    .header {
      width: 80%;
      margin: 0 auto;
      border-bottom: 2px solid #990f0d;

      .title {
        font-size: 25px;
        font-weight: 600;
      }
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .contentItem {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .name {
            font-size: 18px;
            font-weight: 800;
            width: 250px;
            overflow-x: scroll;
            text-align: initial;
          }
          .class {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.name::-webkit-scrollbar {
  display: none !important;
}
.AllSubjects {
  height: 90% !important;
  overflow-y: scroll !important;
}
.AllSubjects::-webkit-scrollbar {
  width: 4px;
}
.headerFirst {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 10px;
    }
  }
}
