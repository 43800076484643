.body {
  width: 100%;
  position: relative;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

.subWrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
}

.add {
  cursor: pointer;
  .addImage {
    width: 35px;
    height: 35px;
  }
}

.lists {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

// .list {
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   align-items: center;
//   margin-bottom: 1rem;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   background: #f8cdd44f;
//   padding: 20px 40px;
//   margin: 20px auto;
// }

.listItem {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.listItemText {
  font-size: 1.2rem;
  font-weight: bold;
}

.listItemVal {
  font-size: 1.2rem;
}

.formGroup {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  .formLabel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;
  }
  .formInput {
    border: 1px solid #e7ecf2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-indent: 10px;
    margin-bottom: 15px;
  }
}

.formButton {
  width: 150px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: green;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 25px 30px 25px;
}

.formRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  // flex-wrap: wrap;
}

.formCol {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  // margin: 0 10px;
}

.buttonRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.delete {
  cursor: pointer;
  .deleteImage {
    width: 35px;
    height: 35px;
  }
}

.directionRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.directionCol {
  // width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  // margin: 0 10px;
}

.direction {
  width: 30px;
  height: 30px;
  margin: 0 50px;
}

.headerOption {
  width: 70%;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.footer {
  position: fixed;
  bottom: 10px;
  width: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: white;
  padding: 20px 0;
  .footerText {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .footerButton {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding-bottom: 5px;
  }
}

.select {
  margin-bottom: 15px;
}

.edit {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 90%;
  .editImage {
    margin-left: 10px;
    width: 25px;
    height: 25px;
  }
}

.disabled {
  opacity: 0.6;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

.note {
  width: 20%;
  margin-left: auto;
  border: 1px solid black;
  padding: 1px;
}

.clearIcon {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.filterWrapper {
  margin: 0 10px;
}

.body {
  margin: 30px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    .list {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
      margin: 10px 0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}

.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: 800;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin: 0 10px;
  }
}
.number {
  font-size: 14px;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.teacherFilter {
  width: 70%;
  display: flex;
  justify-content: flex-end;
}
.btns {
  display: flex;
  flex-direction: column;
}
.readMoreButton {
  text-align: center;
}
@media (max-width: 500px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .headerOption {
    width: 100%;
  }
  .filterWrapper {
    margin: 0 5px;
  }
  .title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .teacherFilter {
    width: 100%;
  }
  .list {
    flex-direction: column;
    .profile {
      .profileImg {
        margin-right: 50px !important;
      }
    }
  }
  .btns {
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }
  .dltbtn {
    margin-left: 5px;
  }
}
