.body{
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;

    .header{
        width: 100%;
        height: 100px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .logo{
            margin: 0 20px;
            
            .logoImage{
                border-radius: 50%;
                width: 70px;
                height: 70px;
            }
        }
        .title{
            font-size: 30px;
            font-weight: bold;
        }
    }

    .bodyContainer{
        width: 100%;

        .bodyContainerHeader{
            font-size: 30px;
            font-weight: bold;
            margin: 50px 30px;
        }
        .inputContainer{
            padding: 30px 0;
            width: 50%;
            display : flex;
            margin: 0 auto;
            flex-direction: column;
            // border: 2px solid red;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


            .inputRow{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                margin: 10px 0;

                .inputBox{
                    width: 300px;
                    height: 40px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 0 10px;
                    font-size: 15px;
                    font-weight: bold;
                }
            }

            .submitRow{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .submitButton{
                    width: 100px;
                    height: 40px;
                    margin: 20px 0;
                    border: 1px solid green;
                    border-radius: 5px;
                    background-color: green;
                    color: #fff;
                    font-size: 15px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }
}