.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  gap: 1rem;
}
.coverImage {
  background-color: #f7f9fc;
  width: 80%;
  padding: 10px;
  .carouselImg {
    height: 350px;
    object-fit: contain;
  }
}
.form {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .textInput {
    width: 100%;
    height: 30px;
  }
}
.formCheck {
  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .textInput {
    width: 100%;
    height: 30px;
  }
}

.placeholder {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}
.formDate {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pdfLink {
  font-size: 100px;
  align-self: center;
}
.attachment {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .placeholder {
    font-size: 18px;
    font-weight: lighter;
  }
}
.checkboxes {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;
  .checkboxText {
    margin-right: 10px;
  }
}
.attachment:hover {
  box-shadow: #990f0d 0px 2px 8px 0px;
  p {
    color: black;
  }

  color: #990f0d;
}

.inputField {
  border: 1.5px solid #dcdfe3;
  padding-left: 8px;
  border-radius: 4px;
}
.leftCircle {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 30;
  height: 30;
  cursor: pointer;
  left: 25;
  font-size: 50px;
  color: #990f0d;
}
.rightCircle {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 30;
  height: 30;
  cursor: pointer;
  right: 25;
  font-size: 50px;
  color: #990f0d;
}

@media (max-width: 950px) {
  .leftCircle {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 30;
    height: 30;
    cursor: pointer;
    left: 0 !important;
    font-size: 50px;
    color: #990f0d;
  }
  .rightCircle {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 30;
    height: 30;
    cursor: pointer;
    right: 10 !important;
    font-size: 50px;
    color: #990f0d;
  }
  .coverImage {
    background-color: #f7f9fc;
    width: 100%;
    padding: 10px;
  }
  .form {
    width: 70%;
  }
  .formCheck {
    width: 70%;
  }
  .attachment {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .leftCircle {
    width: 0 !important;
    height: 0 !important;
    left: -50px !important;
  }
  .rightCircle {
    width: 0 !important;
    height: 0 !important;
    right: -0 !important;
  }
  .coverImage {
    background-color: #f7f9fc;
    width: 100%;
    padding: 10px;
    .carouselImg {
      height: 350px;
      object-fit: contain;
    }
  }
  .form {
    width: 90%;
  }
  .formCheck {
    width: 90%;
  }
  .attachment {
    width: 70%;
  }
}
