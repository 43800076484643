.body {
  width: 100%;
  // min-height: 60vh;
}
.header {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.headerText {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.button {
  // width: 200px;
  padding: 10px 15px;
  background: #990f0d;
  text-align: center;
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: bold;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;

  .card {
    width: 300px;
    height: 400px;
    background: #990f0d;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 2rem;
      color: white;
    }
  }
  .page {
    font-weight: bold;
    margin: 10px 0;
  }
}

.wrapperCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15vh 0;

  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: 20px;
  }
}
.cautionImg {
  width: 50px;
  height: 50px;
}
#reportCardTemp {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#reportCardPdf {
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 600px) {
  .reportCardTemp{
    width: 100%;
    overflow-x: auto;
  }
}
