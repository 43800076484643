.backgroundUrl{
    // width: 50%;
    // height: 100%;
    // background-size: cover; 
    // background-image: url('../../../assets/logos/SMU_Watermark.png');
    background-size:350px 350px;
    // background-color: rgb(254, 250, 250);
    // background-color: #cccccc;
    // background-image: url('../../assets/bg/school.jpg');
    background-repeat: no-repeat;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background-attachment: fixed;
    background-position: center ;
    // background-color: rgb(145, 143, 143);
    // background-color: #f7d5d5; /* Tint color */
    // background-blend-mode: multiply;
    // background-color: aqua;
}
// display: "flex",
// alignItems: "center",
// justifyContent: "center",
// width: "64%",
// marginLeft: "1vw",

.A4downloadStyle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36.5vw;
    margin-left: 1vw;
}
.fontContainer{
    display: flex;
    font-size: .65rem;
    width: 55%;
}
.fontContainer1{
    display: flex;
    font-size: .95rem;
    width: 55%;
}
@media (max-width: 1450px){
    .A4downloadStyle{
        width: 38.5vw;
    }
}
@media (max-width: 1400px){
    .A4downloadStyle{
        width: 40.5vw;
    }
}
@media (max-width: 1350px){
    .A4downloadStyle{
        width: 41.5vw;
    }
}
@media (max-width: 1300px){
    .A4downloadStyle{
        width: 43.5vw;
    }
}
@media (max-width: 1250px){
    .A4downloadStyle{
        width: 45.5vw;
    }
}
@media (max-width: 1200px){
    .A4downloadStyle{
        width: 47.5vw;
    }
}
@media (max-width: 1150px){
    .A4downloadStyle{
        width: 49.5vw;
    }
}

@media (max-width: 1100px){
    .A4downloadStyle{
        width: 51.5vw;
    }
}
@media (max-width: 1050px){
    .A4downloadStyle{
        width: 54vw;
    }
}
@media (max-width: 1000px){
    .A4downloadStyle{
        width: 57vw;
    }
}
@media (max-width: 950px){
    .A4downloadStyle{
        width: 60vw;
    }
}
@media (max-width: 900px){
    .A4downloadStyle{
        width: 63.5vw;
    }
}
@media (max-width: 850px){
    .A4downloadStyle{
        width: 67.5vw;
    }
}
@media (max-width: 800px){
    .A4downloadStyle{
        width: 71.5vw;
    }
}
@media (max-width: 750px){
    .A4downloadStyle{
        width: 77vw;
    }
}
@media (max-width: 700px){
    .A4downloadStyle{
        width: 82.5vw;
    }
}
@media (max-width: 650px){
    .A4downloadStyle{
        width: 88.5vw;
    }
}
@media (max-width: 625px){
    .A4downloadStyle{
        width: 92vw;
    }
}
@media (max-width: 600px){
    .A4downloadStyle{
        width: 96vw;
    }
}
@media (max-width: 575px){
    .A4downloadStyle{
        width: 100vw;
    }
}
@media (max-width: 550px){
    .A4downloadStyle{
        width: 104vw;
    }
}