.body {
  width: 100%;
  min-height: 60vh;
}
.header {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.headerText {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.button {
  padding: 10px 15px;
  background: #990f0d;
  text-align: center;
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: bold;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;

  .page {
    font-weight: bold;
    margin: 10px 0;
  }
}

#reportCardTemp {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#reportCardPdf {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newReportCardTemp1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 900px) {
  .headerText {
    font-size: 1.1rem;
  }
  .wrapper {
    overflow: auto;
  }
}
@media (max-width: 700px) {
  .newReportCardTemp1 {
    display: initial;
  }
}
@media (max-width: 550px) {
  .headerText {
    font-size: 0.9rem;
  }
  .wrapper {
    overflow: auto;
  }
}
