.allField{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.inputTags{
    width: 50%;
    margin-top:1vw  ;

}
.inputTags input{
    width: 100%;
    height: 2.5vw;
    border: 2px solid rgb(205, 205, 205);
    padding-left: 1vw;
    border-radius: 10px;
    outline: none;
}
.tagContanier{
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
}
.tagContanier p {
    width: 40%;
    font-weight: 700;
    font-size: 15px;
}
.asterisk {
    color: red;
    font-size: 20px;
  }
.certificateName{
    font-size: 1.2rem;
    font-weight: 700;
    text-align: left;
    width: 100%;
  }
  @media (max-width: 950px){
    .tagContanier p {
        font-size: 12px;
    }
    .inputTags input{
        height: 5.5vw;
    }
  }
  @media (max-width: 550px){
    .tagContanier p {
        font-size: 11px;
    }
    .inputTags input{
        height: 7vw;
        font-size: 11px
    }
    .asterisk {
        color: red;
        font-size: 12px;
      }
      .tagContanier{   
        justify-content: space-between;
    }
  }