.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}
.body {
  margin: 35px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .list {
      width: 30%;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      padding: 10px;
      margin: 10px 0;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}

.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.feeStatus {
  width: 100%;
}
.updateFeesIcon {
  font-size: 28px;
  cursor: pointer;
}
.updateButton {
  margin-top: 20px;
}
.downloadBtn {
  background-color: #990f0d;
  padding: 8px;
  text-align: center;
  margin-bottom: 15px;
  color: white;
  font-size: 1.05rem;
  font-weight: 600;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
  width: 15%;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
  font-weight: bold;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 2px;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
}
.fileFormatContainer {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
@media (max-width: 950px) {
  .noResult {
    width: 50%;
  }
}
@media (max-width: 550px) {
  .noResult {
    width: 60%;
    text-align: center;
    font-size: 15px;
  }
  .header {
    flex-wrap: wrap;
    align-items: baseline;
  }
  .updateFeesIcon {
    font-size: 8px !important;
    padding: 5px !important;
  }
  .filterWrapper {
    width: 45%;
    margin: 3px 5px;
  }
  .list {
    width: 100% !important;
  }
  .headerFirst {
    // margin-left: auto;
    // border: 2px solid red;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 30px;
    .statsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .stats {
        font-weight: bold;
        font-size: 11px;
        margin: 0 7px;
      }
    }
  }
}
.classListContainer {
  display: flex;
  overflow-x: auto;
}
.classList {
  margin: 5px;
}
