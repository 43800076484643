.body {
  width: 100%;
  //   min-height: 70vh;
  // margin: 50px 0;
  // position: relative;
}

.calendarWrapper {
  width: 80%;
  margin: 50px auto;
}
.sunday {
  color: red !important;
}

.eventWrap {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 16px;
  width: 80%;
  margin-bottom: 15px;
}
.editImage {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 2px 5px;
}
// .eventLists{
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     width: 50%;
//     min-height: 50%;
//     background: pink;
//     top: 100;
// }
.addButton {
  cursor: pointer;
  // border: 2px solid red;
  background-color: #fdc1a5;
  border-radius: 60%;
  margin: 0 10px;

  position: fixed;
  right: 20px; /* Set the desired distance from the right side */
  top: 90%; /* Set the desired vertical position */
  transform: translateY(-50%); /* Center vertically */
  /* Add other necessary styles */

  .add {
    width: 70px;
    height: 70px;
  }
}
.academicYear {
  cursor: pointer;
  background-color: #fdc1a5;
  border-radius: 60%;
  margin: 0 10px;
  position: fixed;
  right: 20px;
  top: 90%;
  transform: translateY(-50%);
  padding: 10px;

  .add {
    width: 50px;
    height: 50px;
  }
}
.heading {
  font-weight: bold;
  color: brown;
  margin-bottom: 10px;
  background: white;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.subheading {
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px;
}
.subheadingDetail {
  font-weight: 500;
}
.today-indicator {
  display: none;
}

.fc-today-button {
  visibility: hidden !important;
}
.placeholder {
  font-size: 18px;
  margin-bottom: 10px;
}
.textInput {
  width: 100%;
  height: 30px;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  margin-bottom: 15px;
  .textInput {
    width: 100%;
    height: 30px;
  }
}
.formDate {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.dot {
  width: 6px;
  height: 6px;
  background-color: #000;
  border-radius: 50%;
  margin: 2px;
}

.dotContainer {
  display: flex;
  align-items: center;
}
.dateCell {
  height: 60px; /* Adjust the height as needed */
  overflow: hidden;
}
.eventContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.eventDot {
  width: 6px;
  height: 6px;
  margin-right: 2px;
  border-radius: 50%;
}
.selected {
  appearance: none;
  background-color: #fafafa;
  font-weight: bold;
  // padding: 6px 12px;
  font-size: 1em;
  line-height: 1.25;
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  // border: 1px solid lightgray;
  border-radius: 5px;
  // border-bottom: 2px solid #000; /* Change the border color here */
}

.selected:focus {
  border: 2px solid lightgray;
  border-radius: 5px;
}
.todayButton {
  background-color: #ea3c53;
  color: white;
  padding: 6px;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 10px;
}
.academicSession {
  display: flex;
  justify-content: flex-end;
}

.sessionBody {
  display: flex;
  margin-top: 20px;
}
.sessionLeft {
  width: 50%;
}
.sessionRight {
  width: 50%;
  margin-left: 10px;
}

.sessionLeftHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
.customModalContent {
  max-width: 800px !important; /* Set the desired maximum width */
  width: 100% !important; /* Ensure the modal occupies the entire width */
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.centerText {
  text-align: center;
  margin-top: 100px;
  font-weight: bold;
  font-size: 25px;
  background-color: #ea3c53;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.noResult {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  padding: 20px;
  color: red;
}
.holiday {
  background-color: red;
  padding: 5px;
  color: white;
  font-weight: bold;
  border-radius: 20px;
}
.calenderStyle {
  width: 600px;
  border: 2px solid black;
  max-height: 400px;
  position: fixed;
}
.detailsContainer {
  width: calc(95% - 600px);
  margin-left: auto;
}
.detailsTitle {
  width: 80%;
  text-align: end;
}
@media (max-width: 550px) {
  .calenderStyle {
    width: initial;
    position: relative;
  }
  .fc .fc-daygrid-day-top {
    flex-direction: initial !important;
  }
  .detailsContainer {
    width: 100%;
  }
  .detailsTitle {
    width: 100%;
    justify-content: center;
  }
  .heading {
    margin-top: 10px;
  }
}
