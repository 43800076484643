.body {
  .Classlists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .list {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f8cdd44f;
    padding: 20px;
    margin: 10px 0;

    .profile {
      .profileImg {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid black;
      }
    }

    .info {
      .name {
        font-size: 18px;
        margin-bottom: 2px;
        padding: 2px;
      }

      .class {
        font-size: 18px;
        margin-bottom: 2px;
        padding: 2px;
        font-weight: bold;
      }
    }
  }
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .list {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      padding: 20px;
      margin: 10px 0;

      .profile {
        .profileImg {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.lists_elective {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .list {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f8cdd44f;

    padding: 20px;
    margin: 10px 0;

    .profile {
      .profileImg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid black;
      }
    }

    .info {
      .name {
        font-size: 18px;
        margin-bottom: 2px;
        padding: 2px;
      }

      .class {
        font-size: 18px;
        margin-bottom: 2px;
        padding: 2px;
      }

      .number {
        font-size: 14px;
        // margin-bottom: 2px;
      }
    }
  }
}

.electives_container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px;
  width: 40%;
}

.electives {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.elective_heading {
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.elecBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-right: 10px;
}
.stats {
  font-weight: bold;
  font-size: 20px;
  margin: 0 10px;
}
.heading {
  font-size: 20px;
  padding: 1rem;
  font-weight: bold;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
  margin-bottom: 10px;
}
@media (max-width: 950px) {
  .stats {
    font-size: 17px !important;
  }
  .list {
    width: 44% !important;
  }
  .class {
    font-size: 15px !important;
  }
  .name {
    font-size: 15px !important;
  }
  .profile {
    margin-right: 1vw;
  }
  .electives_container {
    width: 80% !important;
  }
}
@media (max-width: 500px) {
  .list {
    width: 90% !important;
    padding: 14px !important;
  }
  .name {
    font-size: 13px !important;
  }
  .class {
    font-size: 12px !important;
  }
  .stats {
    font-size: 13px !important;
  }
  .electives_container {
    width: 90% !important;
  }
  .heading {
    font-size: 15px !important;
  }
}
