.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  gap: 1rem;
}

.form {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  .textInput {
    width: 100%;
    height: 30px;
  }
}

.placeholder {
  font-weight: bold;
}
.editIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9cbca;
  border: 1px solid orchid;
  border-radius: 5px;
  padding: 3px;
  margin-top: 8px;
  cursor: pointer;
}
.editIcon:hover {
  background-color: #990f0d;
  border: 1px solid orchid;
  color: white;
}
.pencilIcon {
  margin-right: 5px;
}

.coverImage {
  background-color: #f7f9fc;
  width: 80%;
  padding: 10px;
  .carouselImg {
    height: 350px;
    object-fit: contain;
  }
}
.formCheck {
  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  .placeholder {
  }
  .textInput {
    width: 100%;
    height: 30px;
  }
}

.placeholder {
  font-size: 18px;
  margin-bottom: 10px;
}
.formDate {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 50px 0;
  .button {
    width: 100px;
    height: 40px;
    background: #990f0d;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 50px 0;
  .button2 {
    width: 100px;
    height: 40px;
    background: #228b15;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.chooseImage {
  margin-top: 10px;
  // width: 100%;
}

.fileInput {
  margin-left: 100px;
}
.pdfLink {
  font-size: 100px;
  align-self: center;
}
.attachment {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .placeholder {
    font-size: 18px;
    font-weight: lighter;
  }
}
.checkboxes {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;
  .checkboxText {
    margin-right: 10px;
  }
}
.attachment:hover {
  box-shadow: #990f0d 0px 2px 8px 0px;
  p {
    color: black;
  }

  color: #990f0d;
}

.profile {
  width: 50%;
  margin: 0 auto;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .profileImage {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
}
.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

.attachmentImg {
  height: 500px;
  width: 700px;
  object-fit: fill;
}
.profileImg {
  width: 20px;
  height: 20px;
}
.searchStudentList {
  // width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
}

// .carousel .slider-wrapper.axis-horizontal .slider {
//   // -ms-box-orient: horizontal;
//   // display: -moz-flex;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.img {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .placeholder {
    font-size: 18px;
    font-weight: lighter;
  }
}
.datePicker::placeholder {
  color: #a8bad0;
}
.asterisk {
  color: red;
  font-size: 20px;
}
.inputField {
  border: 1.5px solid #dcdfe3;
  padding-left: 8px;
  border-radius: 4px;
}
.leftCircle {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 30;
  height: 30;
  cursor: pointer;
  left: 25;
  font-size: 50px;
  color: #990f0d;
}
.rightCircle {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 30;
  height: 30;
  cursor: pointer;
  right: 25;
  font-size: 50px;
  color: #990f0d;
}
.buttonImage {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
@media (max-width: 950px) {
  .leftCircle {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 30;
    height: 30;
    cursor: pointer;
    left: 0 !important;
    font-size: 50px;
    color: #990f0d;
  }
  .rightCircle {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 30;
    height: 30;
    cursor: pointer;
    right: 10 !important;
    font-size: 50px;
    color: #990f0d;
  }
  .coverImage {
    background-color: #f7f9fc;
    width: 100%;
    padding: 10px;
  }
  .form {
    width: 70%;
  }
  .formCheck {
    width: 70%;
  }
  .attachment {
    width: 60%;
  }
  .titleArea {
    min-height: 55px !important;
  }
}

@media (max-width: 500px) {
  .leftCircle {
    width: 0 !important;
    height: 0 !important;
    left: -50px !important;
  }
  .rightCircle {
    width: 0 !important;
    height: 0 !important;
    right: -0 !important;
  }
  .coverImage {
    background-color: #f7f9fc;
    width: 100%;
    padding: 10px;
    .carouselImg {
      height: 350px;
      object-fit: contain;
    }
  }
  .form {
    width: 90%;
  }
  .formCheck {
    width: 90%;
  }
  .attachment {
    width: 70%;
  }
  .titleArea {
    min-height: 60px !important;
    // overflow: scroll !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }
  .buttonImage {
    display: flex;
    flex-direction: column !important;
  }
}
