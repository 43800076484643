@import '../../global.scss';
.body {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    height: 80vh;
    width: 80%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include mobile{
        background-image: url('../../assets/bg/SMU_small.jpg');
        background-size: cover; 
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-position: center top;
        background-color: #f7d5d5; /* Tint color */
        background-blend-mode: multiply;
    }
}

.cover {
    width: 50%;
    height: 100%;
    background-size: cover; 
    background-image: url('../../assets/bg/SMU_small.jpg');
    // background-image: url('../../assets/bg/school.jpg');
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center top;
    background-color: #f7d5d5; /* Tint color */
    background-blend-mode: multiply;
    @include mobile{
        display: none;
    }
}

.inputDiv {
    width: 50%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    form {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        // margin: 25% 0;
        // border: 1px solid red;
        @include mobile{
           background-color: whitesmoke;
           height: 60%;
           width: 80%;
           border-radius: 5%;
           opacity: 0.95;
       
        }
    }
    @include mobile{
        width: 100%;
   
    }
}

.heading {
    font-size: 5vh;
    margin: 0;
    border-bottom: 4px solid #990f0d;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.input {
    margin: 10px 0 0 0;
    height: 40px;
    width: 310px;
    border-radius: 5px;
    border: 2px solid #dedede;
    text-indent: 10px;
    &:placeholder {
        margin-left: 10px;
        font-size: 15px;
    }
    &::-webkit-input-placeholder {
        margin: 20px;
        font-size: 15px;
    }
    &::-moz-placeholder {
        margin: 20px;
        font-size: 15px;
    }
    &:-ms-input-placeholder {
        margin: 20px;
        font-size: 15px;
    }
    &:-moz-placeholder {
        margin: 20px;
        font-size: 15px;
    }
    &:focus {
        border: 2px solid #990f0d;
    }
}

.submitButton {
    width: 150px;
    padding: 15px;
    border-radius: 0;
    border: 0;
    background: #990f0d;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.submitText {
    margin: 0;
    color: white;
    font-size: 20px;
}

.error {
    color: #990f0d;
    font-weight: 800;
}
.input {
    margin: 10px 0 0 0;
    height: 40px;
    width: 260px;
    border-radius: 5px;
    border: 2px solid #dedede;
    text-indent: 10px;
    &:placeholder {
      margin-left: 10px;
      font-size: 15px;
    }
    &::-webkit-input-placeholder {
      margin: 20px;
      font-size: 15px;
    }
    &::-moz-placeholder {
      margin: 20px;
      font-size: 15px;
    }
    &:-ms-input-placeholder {
      margin: 20px;
      font-size: 15px;
    }
    &:-moz-placeholder {
      margin: 20px;
      font-size: 15px;
    }
    &:focus {
      border: 2px solid #990f0d;
    }
  }
@media (max-width: 950px){
    .wrapper{
      background-image: url("../../assets/bg/SMU_small.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      // background-attachment: fixed;
      background-position: center top;
      background-color: #f7d5d5; /* Tint color */
      background-blend-mode: multiply;
    }
    
    .cover{
      display: none;
    }
    .inputDiv{
      width: 100%;
      form{
        background-color: whitesmoke !important;
        height: 50% !important;
        width: 80% !important;
        border-radius: 5% !important;
        opacity: 0.95 !important;
      }
    }
    .input{
      height: 55px;
      width: 300px;
    }
    .countryCode{
      height: 55px;
      width: 55px;
    }
    .input2{
      height: 55px;
      width: 260px;
    }
    .heading{
      font-size: 4vh;
    }
    .multiLoginContainer{
      background-color: whitesmoke !important;
      height: 50% !important;
      width: 80% !important;
      border-radius: 5% !important;
      opacity: 0.95 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    }
    @media (max-width: 500px){
      .wrapper{
        background-image: url("../../assets/bg/SMU_small.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-position: center top;
        background-color: #f7d5d5; /* Tint color */
        background-blend-mode: multiply;
      }
      .InputDiv{
        width: 90% !important;
      }
      .inputDivContainer{
        display: flex;
        align-items: center !important;
      }
      .cover{
        display: none;
      }
      .inputDiv{
        width: 100%;
        form{
          background-color: whitesmoke !important;
          height: 50% !important;
          width: 90% !important;
          border-radius: 5% !important;
          opacity: 0.95 !important;
        }
      }
      .input{
        height: 45px;
        width: 200px;
      }
      .input_group{
        width: 80% !important;
        height: 45px !important;
      }
      .countryCode{
        height: 45px;
        width: 40px;
      }
      .input2{
        height: 45px;
        width: 250px;
      }
      .heading{
        font-size: 3vh;
      }
      .thumbnail{
    width: 225px;
    height: 65px;
      }
      .profileImage{
        height: 4vw;
        width: 4vw;
      }
      .profile{
        font-size: 1rem;
      }
      }
.changeType{
  font-size: .9rem;
  margin-top: 20px;
  // text-align: end;
  // width: 90%;
  position: absolute;
  bottom: 3vw;
  right: 3vw;
}
.forgetPassword{
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  color: #1877f2;
}