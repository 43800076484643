$clr-pink: #a54ba5;
$clr-purple: #000080;
$clr-lightPink: #fadadd;
$clr-lightPurple: #ead7ff;
$clr-greyPurple: #dfd1ec;
$clr-grey: #c7c6c7;
//remove these 2 css extra for alignment with pdf
// .margin section {
//   padding-top: 8px;
//   padding-bottom: 6px;
// }
.markContainer td {
  // padding-top: 2px;
  // padding-bottom: 0.5px;
}
//till here
.body {
  height: 832px;
}
.bodyfor9 {
  height: 950px;
}
table {
  border: transparent;
}
tr,
td {
  border: transparent;
  text-align: center;
  padding: 0px 5px 10px 15px;
  font-size: 1.1em;
}
.borderContainer {
  font-family: sans;  
  margin:  1em;
  border: solid 2px $clr-pink;
  border-radius: 5px;
  font-size: 0.55em;
  width: 152mm;
}
.container {
  margin: 2px;
  border: solid 2px $clr-purple;
  border-radius: 5px;
}
.header {
  display: flex;
  justify-content: space-around;
  font-weight: 800;
  color: $clr-purple;
  margin-bottom: 3px;

  .headerBlueLogo {
    width: 70px;
    height: 84px;
    padding-top: 5px;
  }
  .headerLogo {
    width: 80px;
    height: 84px;
    padding-top: 4px;
  }
  div {
    text-align: center;
    h1 {
      padding-top: 0px;
      color: $clr-purple;
      font-size: 1.7em;
    }
    p {
      font-size: 1.2em;
      font-weight: 500;
    }
    h4 {
      font-weight: 800;
    }
  }
}
.maintitle {
  background-color: $clr-lightPink;
  color: $clr-purple;
  text-align: center;
  border-top: double 4px $clr-purple;
  border-bottom: double 4px $clr-purple;
  padding: 0px 0px 6px 0px;
  margin: 0px 0px 1px 0px;
  font-weight: 800;
  font-size: 1.35em;
}
.maintitle2 {
  background-color: $clr-lightPink;
  color: $clr-purple;
  text-align: center;
  border-top: double 4px $clr-purple;
  border-bottom: double 4px $clr-purple;
  padding: 0px 0px 8px 0px;
  margin: 1.5px 0px 1px 0px;
  font-weight: 800;
  font-size: 1.3em;
}
.title {
  background-color: $clr-greyPurple;
  color: $clr-purple;
  text-align: center;
  border-bottom: solid 1px $clr-purple;
  padding: 0px 0px 7px 0px;
  margin: 0px 0px 2px 0px;
  font-weight: 800;
  font-size: 1.2em;
}
.title2 {
  background-color: $clr-greyPurple;
  color: $clr-purple;
  text-align: center;
  border-top: solid 1px $clr-purple;
  border-bottom: solid 1px $clr-purple;
  padding: 1px 0px 8px 2px;
  margin: 6.5px 0px 1px 0px;
  font-weight: 800;
  font-size: 1.2em;
}
.studentInfo {
  // display: flex;
  font-size: 1em;
  padding-bottom: 3px;
  .info {
    padding-left: 10px;
  }
  .info1 {
    padding-left: 10px;
  }
  .infoDataLeft {
    display: inline-block;
    width: 270px;
  }
  .infoDataLeft1 {
    display: inline-block;
  }
  .infoDetailLeft {
    color: $clr-purple;
    display: inline-block;
    min-width: 100px;
    font-weight: 700;
    vertical-align: top;
  }
  .infoColonLeft {
    vertical-align: top;
    padding-right: 2px;
  }
  .infoDetailRight {
    color: $clr-purple;
    display: inline-block;
    min-width: 90px;
    font-weight: 700;
  }
}
.tableWrapper {
  position: relative;
  text-align: center;
  table {
    width: 100%;
    border-bottom: solid 1px $clr-purple;
    table-layout: fixed;
  }
  .tableBox {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .tableHeader {
    background-color: $clr-grey;
    font-weight: 700;
    border-bottom: solid 1px $clr-purple;
    border-top: solid 1px $clr-purple;
  }
  .leftColumn {
    border-right: solid 1px $clr-purple;
    text-align: left;
  }
  .leftcenteredColumn {
    border-right: solid 1px $clr-purple;
    text-align: center;
    font-size: 1em;
  }
  .grandTotal {
    color: $clr-purple;
    font-weight: 700;
    text-align: left;
    border-right: solid 1px $clr-purple;
  }
}
.tableWrapper1 {
  position: relative;
  text-align: center;
  table {
    width: 100%;
    border-bottom: solid 1px $clr-purple;
    // table-layout: fixed;
  }
  .tableBox {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .tableHeader {
    background-color: $clr-grey;
    font-weight: 700;
    border-bottom: solid 1px $clr-purple;
    border-top: solid 1px $clr-purple;
  }
  .leftColumn {
    border-right: solid 1px $clr-purple;
    text-align: left;
  }
  .leftcenteredColumn {
    border-right: solid 1px $clr-purple;
    text-align: center;
    padding-left: 5px;
    width: 75px;
  }
  .leftValues {
    width: 280px;
  }
  .grandTotal {
    color: $clr-purple;
    font-weight: 700;
    text-align: left;
    padding-left: 45px;
    border-right: solid 1px $clr-purple;
    border-top: solid 1px $clr-purple;
    border-bottom: solid 1px $clr-purple;
  }
  .spannedColumn {
    border-right: solid 1px $clr-purple;
    text-align: left;
    padding-left: 45px;
  }
}
.tableWrapper2 {
  position: relative;
  text-align: center;
  table {
    width: 100%;
    border-bottom: solid 1px $clr-purple;
    // table-layout: fixed;
  }
  .tableBox {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .tableHeader {
    background-color: $clr-grey;
    font-weight: 700;
    border-top: solid 1px $clr-purple;
  }
  .tableHeader2 {
    background-color: $clr-grey;
    font-weight: 700;
    border-bottom: solid 1px $clr-purple;
  }
  .spannedHeader {
    padding-top: 2px;
    border-bottom: solid 1px $clr-purple;
    text-align: center;
    width: 58%;
  }
  .topalignedColumn {
    border-right: solid 1px $clr-purple;
    padding: 4px;
    text-align: center;
    vertical-align: top;
  }
  .leftColumn {
    border-right: solid 1px $clr-purple;
    text-align: left;
    padding-left: 30px;
  }
  .leftcenteredColumn {
    border-right: solid 1px $clr-purple;
    text-align: center;
  }
  .grandTotal {
    color: $clr-purple;
    font-weight: 700;
    text-align: left;
    padding-left: 45px;
    border-right: solid 1px $clr-purple;
    border-top: solid 1px $clr-purple;
    // border-bottom: solid 1px $clr-purple;
  }
  .grandTotal1 {
    font-weight: 700;
    border-right: solid 1px $clr-purple;
  }
  .spannedColumn {
    border-right: solid 1px $clr-purple;
    text-align: left;
    padding-left: 45px;
  }
}
.remark {
  text-align: left;
  padding: 5px 0px 10px 10px;
  border-bottom: double 5px $clr-purple;
  font-size: 1.2em;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  margin: 1px 0px 5px 0px;
  // padding-top: 0.5px;
  border-top: 1px solid $clr-purple;
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  img {
    width: 80px;
  }
}
.instructions {
  text-align: center;
}
.emptyRow {
  height: 15px;
  border-right: solid 1px $clr-purple;
  margin-bottom: 1px;
}
.gradesInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  margin-bottom: 4px;
  border-top: solid 1px $clr-purple;
}
.gradesInfohead {
  font-weight: 700;
  margin-bottom: 3px;
}
.gradesInfoBox {
  border: 1px solid black;
  // padding-left: 10px;
  // padding-right: 10px;
  // width: 500px;
  // padding-bottom: 3px;
  // text-align: center;\
  padding-left: 5px;
  /* padding-right: 10px; */
  width: 550px;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-bottom: 1px;
}
.assesmentWidth {
  width: 100px;
  border-right: 1px solid $clr-pink;
}
// @media (max-width: 500px) {
//   .borderContainer {
//     font-family: sans;
//     margin: 1em;
//     border: solid 2px $clr-pink;
//     border-radius: 5px;
//     font-size: 0.55em;
//     width: 100%;
//   }
// }
.watermarkHeight{
  width: 110mm;
        height: 283.5px;
        opacity: 0.3;
        padding: 40px 0px 0px 157px;
}
@media (max-width: 600px) {
//  .watermarkHeight{
//   height: 397.5px;
  
//   }
}