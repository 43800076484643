.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.heading {
    font-size: 50px;
    font-weight: bold;
    padding: 1rem;
    color: white;
    border-radius: 20px;
    background-color: #990f0d;
}