.body {
    overflow: hidden ;
    display: flex;
    flex-direction: column;
    align-items: center;
    textarea,
    input {
        background: white;
    }
}

.forms {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile {
    width: 50%;
    margin: 0 auto;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .profileImage {
        border-radius: 50%;
        width: 150px;
        height: 150px;
    }
}

.profiles {
    display: flex;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.form {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 10px;
    background: white;
    // border: 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    border-radius: 5px;
    // border-radius: 20px;
    // .placeholder {}
    .textInput {
        width: 200px;
        height: 30px;
    }
    p{
        padding-bottom: 10px;
        font-weight: 700;
    }
}

.formc {
    display: flex;
    align-items: flex-start;
    gap: 10rem;
    margin: 0 10px;
    background: white;
    // border: 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 5px;
    // border-radius: 20px;
    .placeholder {}
    .textInput {
        width: 200px;
        height: 30px;
    }
}

.formDate {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // border: 2px solid red;
    .placeholder {}
}

.chooseImage {
    margin-top: 10px;
    // width: 100%;
}

.fileInput {
    margin-left: 100px;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0 50px 0;
    gap: 16px;
    .button {
        width: 100px;
        height: 40px;
        background: #990f0d;
        border-radius: 10px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
}

.heading {
    font-size: 20px;
    font-weight: 700;
    // text-decoration: underline;
    padding: 10px;
}

.loaderWrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
}

.formGroup {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    .formLabel {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1px;
    }
    .formInput {
        border: 1px solid #e7ecf2;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        text-indent: 10px;
        margin-bottom: 15px;
    }
}

.formButton {
    width: 150px;
    align-self: center;
    height: 40px;
    border-radius: 5px;
    background-color: green;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 25px 30px 25px;
}

.filterWrapper {
    margin: 10px;
}