.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: white !important;
  color: rgb(13, 1, 1) !important;
  font-weight: bold;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #990f0d !important;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
  color: #990f0d !important;
  border: 2px solid #990f0d;
}
.react-datepicker__day--sunday {
  color: red !important;
}

.react-datepicker__day-names > :first-child {
  color: red !important;
}

.carousel .slider.animated {
  transition: all 1s ease-in-out !important;
}
/* .fc-toolbar-chunk{
  visibility: hidden !important;

} */
.fc-today-button{
  visibility: hidden !important;
}
.fc-button-group{
  visibility: hidden !important;
}
.fc-toolbar-chunk{
  /* visibility: hidden !important; */
  display: none !important;
}
.fc-col-header-cell.fc-day-sun {
  /* height: 70px;
  margin: 20px; */
  padding-top: 20px; 
   background-color:#fed383;
}
.fc-daygrid-day.fc-day {
  height: 30px !important; /* Adjust the height value as desired */
}
.fc-col-header-cell.fc-day-mon {
  height: 70px;
  margin: 20px;
  padding-top: 20px;
  background-color: #a19797;
  color: white;
}
.fc-col-header-cell.fc-day-tue {
  height: 70px;
  margin: 20px;
  padding-top: 20px;
  background-color: #a19797;
  color: white;
}
.fc-col-header-cell.fc-day-wed {
  height: 70px;
  margin: 20px;
  padding-top: 20px;
  background-color: #a19797;
  color: white;
}
.fc-col-header-cell.fc-day-thu {
  height: 70px;
  margin: 20px;
  padding-top: 20px;
  background-color: #a19797;
  color: white;
}
.fc-col-header-cell.fc-day-fri {
  height: 70px;
  margin: 20px;
  padding-top: 20px;
  background-color: #a19797;
  color: white;
}
.fc-col-header-cell.fc-day-sat {
  height: 70px;
  margin: 20px;
  padding-top: 20px;
  background-color: #a19797;
  color: white;
}
.fc .fc-toolbar-title {
  font-weight: bold;
}
/* .fc-scrollgrid{
  border: 2px solid black !important;
} */

/* .fc-daygrid-day-events{
  display: none
} */

.fc-daygrid-day.fc-day-selected {
  background-color: inherit !important;
}
.fc-daygrid-day-events{
  display: none !important;
}
.fc .fc-toolbar.fc-header-toolbar{
  display: none;
}
.css-xxkadm{
  border: 1px solid black !important;
  color: blue !important;
  background:  #fafafa !important;
}
/* Report Centre Module css */
.custom-slide {
    width: 100% !important;
    margin-bottom: 20px !important;
    padding: 10px !important;
}
