.mainContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.tagContanier{
    display: flex;
    align-items: baseline;
    // justify-content: center;
    width: 80%;
    margin-top: 1.5vw;
}
.tagContanier p {
    width: 30%;
    font-weight: 700;
    font-size: 18px;
}
.asterisk {
    color: red;
    font-size: 20px;
  }
  .dateTagContanier{
    display: flex;
    align-items: baseline;
    // justify-content: center;
    width: 80%;
    margin-top: 2vw;
  }
  .dateTagContanier p {
    width: 30%;
    font-weight: 700;
    font-size: 18px;
}
.datePicker{
    height: 3vw;
    width: 20vw;
    margin-top: .5vw;
}
.datePicker::placeholder {
  color: #a8bad0;
  padding-left:10px ;
}