@import url("../../Styles/color.css");
.body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  background: var(--SecondaryColor);
}
.modalBody {
  margin: 0;
  width: 100%;
  background: var(--SecondaryColor);
}
.bodyDiv {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: var(--SecondaryColor);
}
.Wrapper {
  width: 90%;
  margin: 0 auto;
  margin-top: 105px;
}
.name {
  font-size: 20px;
  font-weight: bold;
  color: var(--TextColor);
}
.navBar {
  //   margin: 25px 0;
  //   margin-left: 10px;
  padding: 25px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //   position: fixed;
  width: 90%;
  //   z-index: 9999;
  margin: auto;
  //   margin-left: 60px;
}

a {
  text-decoration: none;
  font-weight: bold;
}
.navLeft {
  display: flex;
  align-self: center;
  .logOutImg {
    height: 52px;
  }
}
.navItem {
  width: 50px;

  img {
    padding-bottom: 10px;
    height: 48px;
    width: 35px;
  }
}
.iconImages {
  width: 41px;
  height: 40px;
  color: #b92b2b;
}
.lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}
.list {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 10px;
}
.listWithoutRead {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #eee;
  padding: 20px 10px;
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  flex: 0.9;
  color: black;
}
.fontSize {
  font-weight: 700;
  font-size: 17px;
}
.date {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
}
.delContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.buttonLoad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 80%;
}
.headerFilterWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}
.filterWrapper {
  margin: 0 10px;
}
.formGroup {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  .formLabel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;
  }
  .formInput {
    border: 1px solid #e7ecf2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-indent: 10px;
    margin-bottom: 15px;
  }
}
.profileContainer {
  display: flex;
  align-items: center;
}

.navItems {
  position: relative;
  margin-right: 10px;
  .notificationIcon {
    position: relative;
  }

  .notificationCount {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 6px;
    font-size: 10px;
    font-weight: bold;
  }
  &:hover .notificationsDropdown {
    display: block;
  }
}
.notificationsDropdownItem {
  padding: 10px;
}
.notificationHeading {
  font-weight: bold;
  margin: 5px;
  margin-left: 15px;
  font-size: 18px;
}
.notificationsDropdown {
  width: 400px;
  position: absolute;
  top: 100%;
  right: -200px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  display: none;
  overflow-y: scroll;
  z-index: 1;
  background-color: #fff;
  max-height: 400px;
  transition: max-height 0.8s ease, opacity 0.8s ease;
}
.pageCount {
  background: teal;
  border-radius: 50%;
  padding: 2px 8px;
  color: white;
  font-weight: bold;
}
.errorContainer {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorSubContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  background: rgb(240, 7, 19);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 15px;
  padding: 20px;
  margin-top: 150px;
}
.errorCodeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 800;
}
.code4 {
  font-size: 30px;
  font-weight: bold;
  margin-right: 2px;
  margin-left: 2px;
}
.errorText {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
.errorText2 {
  color: white;
  font-weight: bold;
  margin: 5px;
}

@media (max-width: 500px) {
  .navLeft {
    align-items: center;
  }
  .name {
    font-size: 10px;
  }
  .navItem {
    width: 33px;
  }
  .iconImages {
    width: 30px !important;
    height: 30px !important;
    padding-bottom: 0px !important;
    color: #b92b2b;
  }
  .crossIcon {
    padding-left: 5px;
    font-weight: 500;
    width: 32vw;
    height: 7vw;
    font-size: 12px;
  }
}
.redIcon {
  color: red;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
