.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.headerFirst {
  display: flex;
  flex-direction: row;
  align-items: center;
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin: 0 4px;
  }
}
.body {
  margin: 50px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .list {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
      margin: 20px 0;
      .profile {
        .profileImg {
          width: 70px;
          height: 70px;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
      }
    }
  }
}
.tooltipStyles {
  position: absolute;
  color: black;
  padding: 10px;
  border-radius: 5px;
  top: 30px;
  right: 25px;
  width: 150px;
  transform: translateX(25%);
  background-color: #fafafa;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.closeButton {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.profileIconsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  margin-right: 10px;
  border: 2px solid black;
}
.profileStudentIcon {
  height: 33px;
  width: 43px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
.profileTeacherIcon {
  height: 33px;
  width: 43px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}
.profileAdminIcon {
  height: 33px;
  width: 43px;
  border-right: 1px solid black;
  border-top: 1px solid black;
}
.profileManagementIcon {
  height: 33px;
  width: 43px;
  border-left: 1px solid black;
  border-top: 1px solid black;
}
.mainContainer {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 16px;
}
.wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.forWidthLeft {
  width: 40%;
  height: 320px;
  max-height: 320px;
  overflow-y: scroll;
}
.forWidthRight {
  width: 60%;
  height: 320px;
  max-height: 320px;
  overflow-y: scroll;
}
.stepHead {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
}
.stepHead:hover {
  cursor: pointer;
}
.stepNo {
  background-color: #e0fcf4;
  border-radius: 50%;
  font-weight: bold;
  padding: 2px 7px 2px 7px;
}
.stepTitle {
  font-weight: bold;
  width: 100%;
  display: flex;
  text-align: justify;
  margin-left: 10px;
  padding: 6px;
}
.hoveredStep {
  background-color: #ffecd4;
  border-radius: 5px;
  margin-bottom: 5px;
}
.nothingFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nothingFoundImage {
  width: 70%;
  height: 250px;
}
.nothingFoundText {
  font-size: 20px;
  font-weight: bold;
  color: blue;
  margin-top: 4px;
}
.noModuleData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: red;
  font-weight: bold;
  font-size: 20px;
}
.sectionContainer {
  border-radius: 5px;
  margin: 2px 10px 15px;
  padding: 10px;
  background: #f3e1f3;
}
.mediaContainer {
  padding: 5px;
  margin: 5px 5px 10px 10px;
  border-radius: 5px;
  background: rgb(208, 236, 212);
  border: 2px solid rgb(208, 236, 212);
}
.valueContainer {
  padding-bottom: 5px;
  margin: 5px 5px 10px 10px;
  border-radius: 5px;
  background: rgb(208, 236, 212);
}
.carouselmediaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onemedia {
  width: 800px;
  height: 250px;
}
.stepContent {
  padding: 5px;
  margin-left: 15px;
  text-align: justify;
}
