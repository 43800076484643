.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}

.body {
  margin: 50px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    // border: 2px solid red;
    .list {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      padding: 20px;
      margin: 10px 0;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}

.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: 800;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
.ManualButton {
  padding: 8px 12px;
  background-color: #5cb85c;
  color: white;
  border-radius: 5px;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
