.container-2{
    width: 200px;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
  }
  
  .container-2 input#search{
    width: 45px;
    height: 45px;
    background: #d5ddeb;
    border: none;
    font-size: 10pt;
    float: right;
    color: #262626;
    padding-left: 40px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #fff;
    -webkit-transition: width .55s ease;
    -moz-transition: width .55s ease;
    -ms-transition: width .55s ease;
    -o-transition: width .55s ease;
    transition: width .55s ease;
  }
  
  .container-2 input#search::-webkit-input-placeholder {
     color: #65737e;
     /* padding-left: 5px; */
  }
   
  .container-2 input#search:-moz-placeholder { /* Firefox 18- */
     color: #65737e;  
  }
   
  .container-2 input#search::-moz-placeholder {  /* Firefox 19+ */
     color: #65737e;  
  }
   
  .container-2 input#search:-ms-input-placeholder {  
     color: #45494d;  
  }
  
  .container-2 .icon{
    position: absolute;
    top: 22%;
    z-index: 1;
    color: #4f5b66;
    right:3%
  }
  
  .container-2 input#search:focus, .container-2 input#search:active{
    outline:none;
    width: 200px;
    color: #4f5b66;
  }
   
  .container-2:hover input#search{
  width: 200px;
  }
   
  .container-2:hover .icon{
    color: #93a2ad;
  }