.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}

.body {
  margin: 30px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    // border: 2px solid red;
    .list {
      width: 30%;
      //   display: flex;
      // flex-direction: row;
      align-items: center;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      padding: 20px;
      margin: 10px 0;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
          width: 200px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          height: 25px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}

.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 50px;
        height: 50px;
      }
    }
    .migrateButton {
      cursor: pointer;
      position: absolute;
      bottom: 20px;
      // border: 2px solid red;
      .migrate {
        font-size: 28px;
        margin-left: 15px;
      }
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
  font-weight: bold;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 2px;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
}
.fileFormatContainer {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
@media (max-width: 950px) {
  .header {
    width: 105%;
  }
  .stats {
    font-size: 16px !important;
  }
  .add {
    width: 8vw !important;
    height: 4vw !important;
  }
  .download {
    width: 8vw !important;
    height: 4vw !important;
  }
  .list {
    width: 44% !important;
  }
  .profile {
    margin-right: 1vw;
  }
  .name {
    font-size: 15px !important;
  }
}
@media (max-width: 500px) {
  .headerFirst {
    flex-direction: column;
    align-items: baseline !important;
  }
  .filterWrapper {
    margin: 0 !important;
    margin-right: 3px !important;
  }
  .add {
    width: 9vw !important;
    height: 9vw !important;
  }
  .download {
    width: 13vw !important;
    height: 11vw !important;
  }
  .header {
    margin-bottom: 2vw !important;
  }
  .list {
    width: 90% !important;
    padding: 14px !important;
  }
  .name {
    font-size: 13px !important;
    width: 170px !important;
    height: auto !important;
  }
  .class {
    font-size: 12px !important;
  }
  .number {
    font-size: 12px !important;
  }
  .stats {
    font-size: 13px !important;
  }
  .optionWrapper {
    .migrateButton {
      bottom: 20px;
      right: 15px;
    }
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
