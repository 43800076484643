.Containerbody {
  display: flex;
  // height: 70vh;
  gap: 1rem;
  h1 {
    font-weight: bold;
    font-size: 20px;
  }
  .container1,
  .container2 {
    width: 32.5%;
    margin-right: 1vw;
    border-right: 1px solid black;
    //     height: 70vw;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     gap: 1rem;
    //     overflow-y: scroll;
    //     // padding-top: 45rem;
  }
  .container3 {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    .textarea {
      width: 100%;
      border: 1px solid black;
      padding: 4px;
    }
    .buttonRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 20px 0 50px 0;
      width: 100%;
      .button {
        width: 100px;
        height: 40px;
        background: #990f0d;
        border-radius: 10px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5rem;
}
.containers1 {
  height: 30vw;
  // width: 30%;
  overflow-y: scroll;
}
.textContainer {
  margin-bottom: 5px;
}
.textLabel {
  font-size: 1.1rem;
}
.container3Text {
  width: 100%;
  text-align: center;
}
.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}

.headerFirst {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: 800;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 60%;
  left: 0;
  width: 30%;
  text-align: center;
}
.loaderWrapper2 {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  text-align: center;
}
.profile {
  width: 4vw;
  height: 4vw;
  margin-right: 0.5vw;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid black;
}
.student {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 1vw;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039);
  padding: 5px;
  margin: 10px 0;
}
.lists {
  width: 90%;
  height: 20vw;
  overflow-y: scroll;
}
.lists::-webkit-scrollbar {
  display: none;
}
.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 1.5vw;
}
.messageHeader {
  font-size: 1.2rem;
  font-weight: 400;
}
.messageBoxContainer {
  width: 100%;
  padding: 1vw;
}
.switchContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.switchContainerText {
  margin-right: 1vw;
}
.plivoKey {
  width: 60%;
  font-weight: bold;
}
.plivoValue {
  width: 50%;
}
.plivoColon {
  width: 5%;
  font-weight: bold;
}
.header3 {
  display: flex;
  width: 100%;
  padding: 1vw;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 900px) {
  .Containerbody {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    // height: 70vh;
    gap: 1rem;
    h1 {
      font-weight: bold;
      font-size: 20px;
    }
    .container1,
    .container2 {
      width: 47%;
      margin-right: 1vw;
      height: 46vw;
      border-right: none;
      //     height: 70vw;
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: center;
      //     gap: 1rem;
      //     overflow-y: scroll;
      //     // padding-top: 45rem;
    }
    .container3 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      margin-top: 50px;
      .textarea {
        width: 100%;
        border: 1px solid black;
        padding: 4px;
      }
      .header3 {
        justify-content: flex-end;
      }
      .buttonRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 20px 0 50px 0;
        width: 100%;
        .button {
          width: 100px;
          height: 40px;
          background: #990f0d;
          border-radius: 10px;
          color: white;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
  .lists {
    width: 100%;
    height: 43vw;
    overflow-y: scroll;
  }
  .switchContainer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: end;
  }
}
@media (max-width: 550px) {
  .Containerbody {
    display: flex;
    flex-direction: column;
    // height: 70vh;
    gap: 1rem;
    h1 {
      font-weight: bold;
      font-size: 20px;
    }
    .container1,
    .container2 {
      width: 95%;
      margin-right: 1vw;
      height: 79vw;
      border-right: none;
      //     height: 70vw;
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: center;
      //     gap: 1rem;
      //     overflow-y: scroll;
      //     // padding-top: 45rem;
    }
    .container3 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      .textarea {
        width: 100%;
        border: 1px solid black;
        padding: 4px;
      }
      .buttonRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 20px 0 50px 0;
        width: 100%;
        .button {
          width: 100px;
          height: 40px;
          background: #990f0d;
          border-radius: 10px;
          color: white;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
  .lists {
    width: 100%;
    height: 57vw;
    overflow-y: scroll;
  }
  .profile {
    width: 11vw;
    height: 14vw;
    margin-right: 0.5vw;
  }
  .plivoKey {
    font-size: 12px;
  }
  .plivoValue {
    font-size: 12px;
  }
  .plivoColon {
    font-size: 12px;
  }
  .container3 {
    margin-top: 0px !important;
  }
}
.plivoKey2 {
  width: 95%;
  font-weight: bold;
}