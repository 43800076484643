.body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea,
  input:not(.formElec) {
    background: white;
    border: 1.5px solid #dcdfe3;
    padding-left: 8px;
    border-radius: 4px;
    // height: 40px;
  }
}
.forms {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile {
  margin: 0 auto;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .profileImage {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 1px solid black;
  }
}

.profiles {
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0px;
}

.form {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 8px 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  height: 100px;
  .textInput {
    width: 200px;
    height: 30px;
    border: 1.5px solid #dcdfe3;
    padding-left: 8px;
    border-radius: 4px;
  }
  p {
    padding-bottom: 10px;
    font-weight: 700;
  }
}
.formElec {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .textInput {
    width: 200px;
    height: 30px;
    border: 1.5px solid #dcdfe3;
    padding-left: 8px;
    border-radius: 4px;
  }
  p {
    padding-bottom: 10px;
    font-weight: 700;
  }
}
.formc {
  display: flex;
  align-items: flex-start;
  gap: 10rem;
  margin: 0 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .textInput {
    width: 170px;
    height: 30px;
  }
}

.formDate {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chooseImage {
  margin-top: 10px;
}

.fileInput {
  margin-left: 100px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 50px 0;
  gap: 16px;
  .button {
    width: 100px;
    height: 40px;
    background: #990f0d;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
}

.Elecheading {
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

.formGroup {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .formLabel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;
  }
  .formInput {
    border: 1px solid #e7ecf2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-indent: 10px;
    margin-bottom: 15px;
  }
}

.formButton {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  background-color: green;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 25px 30px 25px;
}

.filterWrapper {
  margin: 10px;
}
.busIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button2 {
  width: 100px;
  height: 40px;
  background: #228b15;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.optionLists {
  width: 30px !important;
  padding: 0px !important;
  appearance: none;
  margin-right: 5px;
  border: 1px solid black;
}
.periodData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.periodinput {
  width: 100%;
  height: 35px;
  border: 2px solid rgb(205, 205, 205);
  padding-left: 1vw;
  border-radius: 10px;
  outline: none;
}
.periodinput2 {
  width: 39%;
  // height: 35px;
  // border: 2px solid rgb(205, 205, 205);
  // padding-left: 1vw;
  // border-radius: 10px;
  // outline: none;
}
// optionLists:: -webkit-appearance {
//   display: none;
// }
@media (max-width: 950px) {
  .row {
    flex-wrap: wrap;
    margin: 0px;
  }
  .form {
    width: 295px;
    margin: 10px;
  }
  .formc {
    gap: 3rem;
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
@media (max-width: 500px) {
  .formc {
    gap: 0rem;
    display: flex;
    flex-direction: column;
  }
  .formc label {
    margin-bottom: 5px;
  }
  .form {
    // border-radius: 20px;
    .placeholder {
    }
    .textInput {
      width: 100%;
      height: 40px;
    }
  }
}

.asterisk {
  color: red;
  font-size: 20px;
}
.datePicker::placeholder {
  color: #a8bad0;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 5px;
  margin: 10px 0;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
}
.icons {
  font-size: 20;
  margin-left: 5px;
  cursor: pointer;
}

.phoneCheckBtn {
  padding: 0px !important;
  background-color: white;
}
.phoneCheckBtnImg {
  width: 75%;
  height: 75%;
  color: white;
  background-color: black;
  border-radius: 50%;
}
.list {
  // width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f8cdd44f;
  padding: 20px;
  margin: 10px 0;
}
.profileImg {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  border: 1px solid black;
}
.number {
  font-size: 14px;
  // margin-bottom: 2px;
}
.class {
  font-size: 14px;
  margin-bottom: 2px;
}
.name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
}
