.body {
  width: 100%;
  // min-height: 60vh;
  // position: relative;
}
.header {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filterWrapper {
  margin: 0 10px;
}
.filterWrapperGeneral {
  margin: 0 10px;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.addButton {
  cursor: pointer;
  // border: 2px solid red;
  margin: 0 10px;
  .add {
    width: 50px;
    height: 50px;
  }
}

.body1 {
  .lists {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    // border: 2px solid red;
  }
  .listing {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    margin: 10px 0;
    gap: 1rem;
    // margin-left: 90px;
  }
}
.examList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 30%;
}
.listingExam {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin: 10px 0;
  gap: 1rem;
}
.list {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin: 10px 0;
  gap: 1rem;
  margin-left: 90px;
}

.downloadGeneral {
  color: white;
  font-weight: bold;
  background-color: #1e9b1e;
  padding: 6px;
  border-radius: 5px;
  // margin: 0 10px;
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin: 0 10px;
  }
}
.wrapper {
  width: 100%;
  // min-height: 100vh;
}
.backupButton {
  background-color: #dd1414;
  color: white;
  border: 1px solid #6c0202;
  border-radius: 5px;
  padding: 5px;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: bold;
}
.retrieveButton {
  background-color: #1e9b1e;
  color: white;
  border: 1px solid #022a02;
  border-radius: 5px;
  padding: 5px;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: bold;
}
.generalListing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 40%;
}
.backupDates {
  font-weight: bold;
  margin-left: 20px;
}
.mainList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.backupFor {
  font-weight: bold !important;
  display: flex;
  align-items: center;
}
.backupDatesContainer {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.examHeader {
  display: flex;
  align-items: center;
}
.students {
  font-weight: bold;
  max-width: 400px;
}
.previewResult {
  background-color: blue;
  color: white;
  border-radius: 5px;
  padding: 5px;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: bold;
}
.downloadResult {
  background-color: green;
  color: white;
  border-radius: 5px;
  padding: 5px;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: bold;
}
.moduleIcon {
  width: 70px;
  height: 70px;
  overflow: hidden;
  margin-right: 10px;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.headerFirst {
  display: flex;
  align-items: center;
}
.previewBtn {
  width: 120px;
}
@media (max-width: 950px) {
  .generalListing {
    // width: 50% !important;
  }
  .backupButton {
    font-weight: 600 !important;
    padding: 5px !important;
  }
  .retrieveButton {
    font-weight: 600 !important;
    padding: 5px !important;
  }
  .backupDates {
    font-size: 0.9em;
  }
  .filterWrapper {
    margin: 0 5px;
    width: 20%;
  }
  .examList {
    // width: 60%;
  }
  .previewResult {
    padding: 5px 8px;
    font-weight: 600;
    font-size: 0.9em;
  }
  .downloadResult {
    padding: 5px 8px;
    font-weight: 600;
    font-size: 0.9em;
  }
  .students {
    font-size: 1em;
  }
}
@media (max-width: 500px) {
  .mainList {
    flex-direction: column !important;
  }
  .listing {
    width: 100% !important;
  }
  .generalListing {
    // width: 70% !important;
  }
  .backupButton {
    font-weight: 400 !important;
    padding: 3px !important;
  }
  .retrieveButton {
    font-weight: 400 !important;
    padding: 3px !important;
  }
  .backupFor {
    font-weight: 600 !important;
    width: 33%;
  }
  .backupDatesContainer {
    flex-direction: row !important;
    width: 90% !important;
  }
  .backupDates {
    font-size: 0.8em;
    margin-left: 5px !important;
  }
  .add {
    width: 30px !important;
    height: 20px !important;
  }
  .downloadGeneral {
    padding: 8px !important;
    font-size: 0.9em;
    font-weight: 500;
  }
  .examHeader {
    flex-direction: column !important;
    align-items: flex-end;
  }
  .examList {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .students {
    font-weight: 500;
    font-size: 0.8em;
  }
  .previewResult {
    padding: 5px;
    font-weight: 500;
    font-size: 0.7em;
  }
  .downloadResult {
    padding: 5px;
    font-weight: 500;
    font-size: 0.7em;
  }
  .headerFirst {
    flex-direction: column;
  }
  .header {
    width: 100%;
  }
  .moduleIcon {
    display: none;
  }
  .listingExam {
    flex-direction: column;
    align-items: flex-start;
  }
  .previewBtn {
    margin-right: 10px;
  }
}
