.wrapper {
  width: 100%;
  position: relative;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.stats {
  font-weight: bold;
  font-size: 20px;
  margin: 0 5px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.headerFilterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
}
.filterWrapper {
  margin: 0 10px;
}
.formGroup {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  .formLabel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;
  }
  .formInput {
    border: 1px solid #e7ecf2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-indent: 10px;
    margin-bottom: 15px;
  }
}
.notificationList{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 120px;
  background: #eee;
}
.listWithoutRead {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 120px;
  background: #eee;
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  flex: 0.9;
  color: black;
}
.fontSize {
  font-weight: 700;
  font-size: 17px;
}
.date {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.delContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.searchIcon{
  font-size: 25px;
}
.modalBox{
  position: absolute;
  background-color: white;
  width: 100%;
  height: 23vw;
  box-shadow: 0px 5px 5px 5px #dbe0e6;

}
.search{
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBox{
  margin-top: 5px;
  width: 75% !important;
}
.lists {
  display: flex;
  width: 100%;
//   align-items: center;
//   justify-content: space-evenly;
  overflow-x: auto;
  // overflow-x: hidden;
  // flex-wrap: wrap;
  // border: 2px solid red;


  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }
  .listing {
    width: 20vw;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f8cdd44f;
    padding: 16px;
    margin: 10px 5px;
    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .profile {
      .profileImg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid black;
      }
    }
    .info {
      .name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2px;
      }
      .class {
        font-size: 14px;
        margin-bottom: 2px;
      }
      .number {
        font-size: 14px;
        // margin-bottom: 2px;
      }
    }
  }
}
.containerBox{
  height: 19vw;
  overflow-y: auto;
}
.checkBox{
  width: 28%;
  // color: #c5e3f3;
}
.ButtonStyles{
  display: flex;
  justify-content: flex-end;
  margin-right: 2vw;
  margin-top: .8vw;
}
.lists2 {
  // display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  // font-size: 16px;
  // font-weight: 700;
  flex-wrap: wrap;
  width: 100%;
  height: 20vw;
  overflow: auto;
  box-shadow: 0px 5px 5px 5px #dbe0e6;
  // border: 2px solid red;
  .listing2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f8cdd44f;
    padding: 16px;
    margin: 10px 0;
    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .profile {
      .profileImg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid black;
      }
    }
    .info {
      .name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2px;
      }
      .class {
        font-size: 14px;
        margin-bottom: 2px;
      }
      .number {
        font-size: 14px;
        // margin-bottom: 2px;
      }
    }
  }
}
.lists::-webkit-scrollbar {
  width: 5px;
}

.lists::-webkit-scrollbar-track {
  background: #f9cbca;
  border-radius: 5px;
}

.lists::-webkit-scrollbar-thumb {
  background: #990f0d;
  border-radius: 5px;
}

.lists::-webkit-scrollbar-thumb:hover {
  background: rgb(245, 241, 241) f0d;
  border-radius: 5px;
}

.lists::-webkit-scrollbar-thumb:active {
  background: #990f0d;
  border-radius: 5px;
}
.selectedBox{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight:700;
  width: 100%;
  height: 20vw;
  overflow: auto;
  box-shadow: 0px 5px 5px 5px #dbe0e6;
}
.createBox{
  padding: 2vw;
  margin-top: 3vw;
  box-shadow: 0px 5px 5px 5px #dbe0e6;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.inputContainer{
  display: flex;
  justify-content: space-around;
  margin: 7px;
  width: 100%;
}
.heading{
  font-size: 1rem;
  font-weight: 700;
}
.inputbox{
  width: 80% !important;
  // opacity: 1;
  border: 1px solid black !important;
  // border-radius: 5px !important;
}
.asterisk {
  color: red;
  font-size: 20px;
}
.lists3{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.selectBoxContainer{
  margin-top: 2vw;
  display: flex;
  justify-content: space-around;
}
.selectBox{
  display: flex;
}
.stepperName{
  margin: 0 8px;
  font-weight: 700;
}
.stepperName:hover{
  cursor: pointer;
}
.containerBox{
  margin-top: 1vw;
  height:18vw;
  overflow: auto;
}
.searchStudentList {
  // width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
}
.profileImg {
  width: 20px;
  height: 20px;
}
.createBox{

}
.coverImage {
  width: 50%;
  padding: 10px;
  .carouselImg {
  height: 310px;
    object-fit: contain;
  }
}
.listParts{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  // font-size: 16px;
  // font-weight: 700;
  flex-wrap: wrap;
}