.body {
  width: 100%;
  // min-height: 65vh;
  position: relative;

  // background: #fafafa;
  ::-webkit-scrollbar {
    height: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }
}
.bodyContainer{
  position: relative;
}
.ModalnameContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.scroll-container {
  overflow-x: scroll;
}

.cards {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 20px;
  position: relative;
  // &::-webkit-scrollbar {
  //     width: 0;  /* Remove scrollbar space */
  //     background: transparent;  /* Optional: just make scrollbar invisible */
  // }
  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }

  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 4px 4px 8px 4px;
    display: inline-block;
    text-align: center;
    // border: 2px solid red;
    width: 450px;
    background: white;
    height: 55vh;
    border-radius: 10px;
    padding: 20px 0;
    margin: 0 10px;

    .header {
      width: 80%;
      margin: 0 auto;
      border-bottom: 2px solid #990f0d;

      .title {
        font-size: 25px;
        font-weight: 600;
      }
    }
    .content {
      // margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-around;

      .contentItem {
        width: 80%;
        // border: 2px solid green;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 7px 0;

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .name {
            font-size: 14px;
            font-weight: 700;
            width: 250px;
            overflow-x: scroll;
            text-align: initial;
          }
          .class {
            font-size: 13px;
          }
        }
        .right {
          cursor: pointer;
          display: flex;
          margin-left: 5px;
          .edit {
            width: 25px;
            height: 25px;
            color: #990f0d;
          }
        }
      }
    }
  }
}
.buttonRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.add {
  // position: absolute;
  width: 50px;
  cursor: pointer;
  height: 50px;
  right: 0;
  // top: 50vw;
  background: #990f0d;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.addForm {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px 0;
}
.addButton {
  width: 100px;
  height: 40px;
  background: #38a169;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 30px 0 20px 0;
}
.addFormTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  // font-size: 20px;
  // font-weight: 600;
  // margin: 10px 0;
}
.addFormInput {
  width: 100%;
  border: 1px solid #e7ecf2;
  border-radius: 5px;
  padding: 5px;
  text-indent: 10px;
  // margin: 15px 0;
}
.name::-webkit-scrollbar {
  display: none !important;
}
.AllSubjects {
  height: 90% !important;
  overflow-y: scroll !important;
}
.AllSubjects::-webkit-scrollbar {
  // display: none !important;
  width: 4px;
}
.Modalname {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 1vw;
}
.filterWrapper {
  margin-bottom: 10px;
}
.asterisk {
  color: red;
  font-size: 20px;
}
.formLabel {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 2px;
}
.card2 {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: inline-block;
  text-align: center;
  // border: 2px solid red;
  width: 160px;
  background: white;
  height: 20vh;
  border-radius: 10px;
  padding: 10px 0px;
  margin: 0 10px;

  .header2 {
    width: 80%;
    margin: 0 auto;
    border-bottom: 2px solid #990f0d;

    .title2 {
      font-size: 9px;
      font-weight: 500;
    }
  }
  .content2 {
    // margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-around;

    .contentItem2 {
      width: 80%;
      // border: 2px solid green;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // margin: 10px 0;

      .left2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .name2 {
          font-size: 5px;
          font-weight: 400;
          width: 120px;
          overflow-x: auto;
          text-align: initial;
        }
        .class2 {
          font-size: 4px;
        }
      }
      .right2 {
        cursor: pointer;
        display: flex;
        margin-left: 5px;
        .edit2 {
          width: 5px;
          height: 5px;
          color: #990f0d;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .name{
    font-size: 13px;

    width: 185px !important;
  }
  .card{
    margin:0px !important
  }
  .contentItem{
    width: 85% !important;
  }
}
