.body {
    width: 100%;
    min-height: 60vh;
    position: relative;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
}

.subWrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 1rem;
}

.add {
    cursor: pointer;
    .addImage {
        width: 35px;
        height: 35px;
    }
}

.edit {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 90%;
    .editImage {
        margin-left: 10px;
        width: 25px;
        height: 25px;
    }
}

.lists {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.list {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px 40px;
    margin: 20px auto;
}

.listItem {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.listItemText {
    font-size: 1.2rem;
    font-weight: bold;
}

.listItemVal {
    font-size: 1.2rem;
}

.formGroup {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    .formLabel {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1px;
    }
    .formInput {
        border: 1px solid #e7ecf2;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        text-indent: 10px;
        margin-bottom: 15px;
    }
}

.formButton {
    width: 150px;
    align-self: center;
    height: 40px;
    border-radius: 5px;
    background-color: green;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 25px 30px 25px;
}

.formRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    // flex-wrap: wrap;
}

.formCol {
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    // margin: 0 10px;
}

.buttonRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.delete {
    cursor: pointer;
    .deleteImage {
        width: 35px;
        height: 35px;
    }
}

.directionRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.directionCol {
    // width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    // margin: 0 10px;
}

.direction {
    width: 30px;
    height: 30px;
    margin: 0 50px;
}

.headerOption {
    width: 50%;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.footer {
    position: fixed;
    bottom: 10px;
    width: 50%;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: white;
    padding: 20px 0;
    .footerText {
        font-size: 1.2rem;
        font-weight: bold;
    }
    .footerButton {
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding-bottom: 5px;
    }
}

.select {
    margin-bottom: 15px;
}

.heading {
    font-weight: bold;
    font-size: 20px;
}

.search {
    display: flex;
    gap: 8px;
}

.loaderWrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
}

.issueReturn {
    width: 100%;
    display: flex;
    text-align: right;
    justify-content: flex-end;
}

.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    gap: 1rem;
}

.form {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 10px;
    background: white;
    // border: 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 5px;
    // border-radius: 20px;
    .placeholder {}
    .textInput {
        width: 100%;
        height: 30px;
    }
}

.formDate {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid black;
    padding: 10px;
    .placeholder {}
}

.buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0 50px 0;
    .button {
        width: 100px;
        height: 40px;
        background: #990f0d;
        border-radius: 10px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
}

.LibraryComponent1{
    width: 25%;
}
.BooksType{
    font-size: 1.3rem;
    margin-bottom: .5vw;
}
.button {
    width: 100px;
    height: 40px;
    background-color: #990f0d !important;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.mainSection{
    display: flex;
    justify-content: space-around;
    margin-top: 1vw;
}

.containerCate {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .containerCate input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmarkCate {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .containerCate:hover input ~ .checkmarkCate {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .containerCate input:checked ~ .checkmarkCate {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmarkCate:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .containerCate input:checked ~ .checkmarkCate:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .containerCate .checkmarkCate:after {
       top: 7px;
      left: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
  .SectionLeft{
    width: 30%;
  }
  .SectionRight{
    width: 65%;
  }
  .SingleBook{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 1vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .icon{
    width: 1.5vw;
    height: 1.5vw;
    margin-right: 1vw;
  }
  .icon img{
    width: 100%;
    height: 100%;
  }
  .bookName{
    font-size: 1.2rem;
    font-weight: bold;
  }
  .icons{
    display: flex;
  }
  .BooksBody{
    position: relative;
  }
  .addIcon{  // position: absolute;
    width: 65px;
    cursor: pointer;
    height: 45px;
    right: 0;
    // top: 50vw;
    background: #990f0d;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .CategoriesBody{
    position: relative;
  }
  .allCategory{
    margin-top: 1vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .singleCategory{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 1vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
.InputGroup{
    display: flex;
    justify-content: space-between;
}
.allField{
    width:100%;
    display:flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
}
.inputTags{
    width: 50%;
    margin-top:1vw  ;

}
.inputTags input{
    width: 100%;
    height: 2.5vw;
    border: 2px solid rgb(205, 205, 205);
    padding-left: 1vw;
    margin-left: 1vw;
    border-radius: 10px;
    outline: none;
    margin-bottom: 1vw;
}
.inputTags Select{
    width: 100%;
    height: 2.5vw;
    margin-left: 1vw;
    border: 2px solid rgb(205, 205, 205);
    padding-left: 1vw;
    padding-right: 2vw;
    border-radius: 10px;
    outline: none;
}
.inputTags Select::placeholder{
    color: #a8bad0 !important;
}
.tagContanier{
    display: flex;
    align-items: baseline;
    // justify-content: center;
    width: 100%;
}
.tagContanier p {
    width: 40%;
    font-weight: 700;
    font-size: 14px;
}
.asterisk {
    color: red;
    font-size: 20px;
  }
.certificateName{
    font-size: 1.2rem;
    font-weight: 700;
    text-align: left;
    width: 100%;
  }
  @media (max-width: 950px){
    .tagContanier p {
        font-size: 12px;
    }
    .inputTags input{
        height: 5.5vw;
    }
    .inputTags Select{
        height: 5.5vw;
    }
  }
  @media (max-width: 550px){
    .tagContanier p {
        font-size: 11px;
    }
    .inputTags input{
        height: 7vw;
        font-size: 11px
    }
    .inputTags Select{
        height: 7vw;
        font-size: 11px
    }
    .asterisk {
        color: red;
        font-size: 12px;
      }
      .tagContanier{   
        justify-content: space-between;
    }
  }