.allField{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.inputTags{
    width: 50%;
    margin-top:1vw  ;

}
.inputTags input{
    width: 100%;
    height: 2.5vw;
    border: 2px solid rgb(205, 205, 205);
    padding-left: 1vw;
    border-radius: 10px;
    outline: none;
}
.tagContanier{
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
}
.tagContanier p {
    width: 40%;
}