.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  gap: 1rem;
  textarea,
  input:not(.fileInput) {
    background: white;
    border: 1.5px solid #dcdfe3;
    padding-left: 8px;
    border-radius: 4px;
  }
}

.form {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  .placeholder {
  }
  .textInput {
    width: 100%;
    height: 30px;
  }
}
.formCheck {
  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9cbca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #990f0d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(245, 241, 241) f0d;
  }
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  // border-radius: 20px;
  .placeholder {
  }
  .textInput {
    width: 100%;
    height: 30px;
  }
}
.checkboxes {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;
  .checkboxText {
    margin-right: 10px;
  }
}
.placeholder {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}
.formDate {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0 50px 0;
  .button {
    width: 100px;
    height: 40px;
    background: #990f0d;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
}

.chooseImage {
  margin-top: 10px;
  // width: 100%;
}

.fileInput {
  margin-left: 100px;
}

.profile {
  width: 50%;
  margin: 0 auto;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .profileImage {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
}
.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.profileImg {
  width: 20px;
  height: 20px;
}
.searchStudentList {
  // width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
}
.asterisk {
  color: red;
  font-size: 20px;
}
.datePicker::placeholder {
  color: #a8bad0;
}
@media (max-width: 950px) {
  .form {
    width: 70%;
  }
  .formCheck {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .form {
    width: 90%;
    display: flex;
  }
  .formCheck {
    width: 90%;
  }
}
