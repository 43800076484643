@import url("./color.jsx");
.light{
    --PrimaryColor:#990f0d; 
    --SecondaryColor:#fafafa; 
    --HeaderNameColor: #ffffff;
    --BoxShadow:#63636333 0px 2px 8px 0px;
    --dashBoardModuleName:#990f0d;
    --TextColor:black;
    --CardColor:rgba(248, 205, 212, 0.3098039216);
    --CertificateBoxShadow:2px 2px 2px 2px rgb(173, 173, 173);
    --HelpStepColor:#eee;
    --HelpStepNumber:#d3f5f0;
    --StaticsCountCard:4px 4px 4px 4px #ccc;
    --StaticsComponentBackground:#f5f4f4;
}
.dark{
    --PrimaryColor:#151719db;;
    --SecondaryColor:#151719db;
    --BoxShadow:#bcbcbcb0 0px 2px 8px 0px;
    --dashBoardModuleName: #ffffff;
    --TextColor:#fafafa;
    --CardColor:rgba(248, 205, 212, 0.3098039216);

}
