.body {
  width: 100%;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
}
.subWrapper {
  width: 90%;
  margin: 0 auto;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.headerText {
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 1rem;
}
.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}
.add {
  cursor: pointer;
  .addImage {
    width: 35px;
    height: 35px;
  }
}
.lists {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 40px;
  margin: 20px auto;
}
.listItem {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.listItemText {
  font-size: 1.2rem;
  font-weight: bold;
}
.listItemVal {
  font-size: 1.2rem;
}
.formGroup {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  .formLabel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;
  }
  .formInput {
    border: 1px solid #e7ecf2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-indent: 10px;
    margin-bottom: 15px;
  }
}
.formButton {
  width: 150px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: green;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 25px 30px 25px;
}
.formRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  // flex-wrap: wrap;
}
.formCol {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  // margin: 0 10px;
}
.buttonRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.delete {
  cursor: pointer;
  .deleteImage {
    width: 35px;
    height: 35px;
  }
}
.directionRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.directionCol {
  // width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  // margin: 0 10px;
}

.direction {
  width: 30px;
  height: 30px;
  margin: 0 50px;
}
.headerOption {
  width: 50%;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.select {
  margin-bottom: 15px;
}

.listSelected {
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 5px 5px 10px 5px;
  padding: 20px 40px;
  margin: 20px auto;
  // border: 2px solid red;
}
.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.wrapperCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15vh 0;

  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: 20px;
  }
}
.cautionImg {
  width: 50px;
  height: 50px;
}
@media (max-width: 950px) {
  .lists {
    flex-direction: column;
  }
  .listSelected {
    width: 80%;
  }
}
@media (max-width: 550px) {
  .listSelected {
    width: 90%;
    padding: 10px;
  }
  .listItemText {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .listItemVal {
    font-size: 0.9rem;
  }
  .header {
    margin: 20px 0;
  }
  .title {
    font-size: 1.1rem;
  }
  .stats {
    font-size: 1.1rem !important;
  }
}
