.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea,
  input {
    background: white;
  }
  padding: 2rem;
}
.profileDownloadContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.forms {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile {
  width: 100%;
  margin: 0 auto;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  border-radius: 50%;
  padding: 4px;
  .profileImage {
    border-radius: 50%;
    border: 1px solid black;
    width: 150px;
    height: 150px;
  }
}

.profiles {
  display: flex;
  gap: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 0px;
}
.form {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .placeholder {
  }
  .textInput {
    width: 100%;
    height: auto;
  }
  p {
    padding-bottom: 10px;
    font-weight: 700;
  }
}

.Elecheading {
  font-weight: bold;
  font-size: 20px;
  padding: 5px;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 5px;
  margin: 10px 0;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
}
.icons {
  font-size: 20;
  margin-left: 5px;
  cursor: pointer;
}
.formc {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin: 0 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
  .textInput {
    width: 200px;
    height: 30px;
  }
}
.statusContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
@media (max-width: 950px) {
  .row {
    flex-wrap: wrap;
    margin: 0px;
  }
  .form {
    width: 225px;
    margin: 10px;
  }
  .formc {
    gap: 3rem;
  }
  .profileImage {
    width: 130px !important;
    height: 130px !important;
  }
}
@media (max-width: 500px) {
  .formc {
    gap: 0rem;
    display: flex;
    flex-direction: column;
  }
  .formc label {
    margin-bottom: 5px;
  }
  .profileImage {
    width: 125px !important;
    height: 125px !important;
  }
}
.inputField {
  border: 1.5px solid #dcdfe3;
  padding-left: 8px;
  border-radius: 4px;
  width: auto;
}
.noResult {
  font-weight: bold;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
