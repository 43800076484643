.body {
  // margin: 50px 0;

  .editImage {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .editbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    margin-left: 5px;
    gap: 15px;
    // justify-content: end;
  }

  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    // border: 2px solid red;
    .list {
      width: 30%;
      display: flex;
      flex-direction: row;
      // align-items: center;
      gap: 10px;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      padding: 20px;
      margin: 10px 0;

      .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .profileImg {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }

      .info {
        .name {
          font-size: 18px;
          margin-bottom: 2px;
          padding: 2px;
          // width: 200px;
          // display: -webkit-box;
          // -webkit-line-clamp: 1;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: normal;
          // height: 25px;
        }

        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}
.classes {
  font-size: 18px;
  margin-bottom: 2px;
  padding: 2px;
}
.class {
  font-size: 18px;
  margin-bottom: 2px;
  padding: 2px;
  font-weight: bold;
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 55px;
}
.Classlists {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.list {
  width: 30%;
  display: flex;
  flex-direction: row;
  // align-items: center;
  gap: 10px;
  // justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f8cdd44f;
  padding: 20px;
  margin: 10px 0;

  .profile {
    .profileImg {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      border: 1px solid black;
    }
  }

  .info {
    .name {
      font-size: 18px;
      margin-bottom: 2px;
      padding: 2px;
      // width: 200px;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: normal;
      // height: 25px;
    }

    .class {
      font-size: 18px;
      margin-bottom: 2px;
      padding: 2px;
    }

    .number {
      font-size: 14px;
      // margin-bottom: 2px;
    }
  }
}
.code {
  font-size: 18px;
  margin-bottom: 2px;
  padding: 2px;
  font-weight: bold;
}
.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  .searchBar {
    width: 280px;
  }
}

.filterWrapper {
  margin: 0 10px;
}

.add {
  cursor: pointer;

  .addImage {
    width: 35px;
    height: 35px;
  }
}

.del {
  cursor: pointer;

  .delImage {
    width: 30px;
    height: 30px;
  }
}

.formGroup {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  .formLabel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1px;
  }

  .formInput {
    border: 1px solid #e7ecf2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-indent: 10px;
    margin-bottom: 15px;
  }
}
.formInput {
  border: 1px solid #e7ecf2;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  text-indent: 10px;
  margin-bottom: 15px;
}
.formButton {
  width: 150px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: green;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 30px 25px 30px 25px;
}
.disabledButton {
  width: 150px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: grey;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: default;
  margin: 30px 25px 30px 25px;
}

.cancelButton {
  width: 150px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: grey;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 30px 25px 30px 25px;
}

.button {
  width: 150px;
  align-self: center;
  height: 40px;
  border-radius: 5px;
  background-color: skyblue;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 25px 30px 25px;
}

.disabled {
  opacity: 0.6;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

.heading {
  font-size: 20px;
  padding: 1rem;
  font-weight: bold;
}

.electives {
  display: flex;
  gap: 10px;
}

.lists_elective {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  // border: 2px solid black;
  .list {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    // justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f8cdd44f;
    padding: 20px;
    margin: 10px 0;

    .profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .profileImg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid black;
      }
    }

    .info {
      .name {
        font-size: 18px;
        margin-bottom: 2px;
        padding: 2px;
        // width: 200px;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: normal;
        // height: 25px;
      }

      .class {
        font-size: 18px;
        margin-bottom: 2px;
        padding: 2px;
      }

      .number {
        font-size: 14px;
        // margin-bottom: 2px;
      }
    }
  }
}

.electives_container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px;
  width: 40%;
}

.electives {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.elective_heading {
  // display: flex;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.elecBox {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 30px;
  margin-right: 10px;
}
.elecimgBox {
  width: 120px;
  display: flex;
  direction: row;
  align-items: center;
  justify-content: right;
  gap: 10px;
}
.icon {
  font-size: 25px;
  color: #555555;
}
.icons {
  font-size: 22px;
}
.textInput {
  width: 45px;
  height: 25px;
  border: 2px solid gray;
  margin: 5px;
  margin-bottom: 4px;
  padding: 5px;
}
.textInputInternal {
  width: 45px;
  height: 25px;
  border: 2px solid gray;
  margin: 5px;
  background-color: rgb(186 173 173 / 20%);
  border-radius: 5px;
  padding-left: 4px;
  font-weight: none !important;
}
.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  height: 50px;
  width: 90%;
  .button {
    width: 80px;
    background: #990f0d;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-left: 70px;
    cursor: pointer;
  }
}
.textInput2 {
  width: 45px;
  height: 25px;
  padding: 5px;
  border: 2px solid gray;
  margin-bottom: 4px;
}
.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 2px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 35px;
        height: 35px;
      }
    }
    .migrateButton {
      cursor: pointer;
      // border: 2px solid red;
      .migrate {
        font-size: 28px;
        margin-left: 15px;
      }
    }
  }
}
.stats {
  font-weight: bold;
  font-size: 20px;
  margin: 0 10px;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
  margin-bottom: 10px;
}
.asterisk {
  color: red;
  font-size: 20px;
}
.searchTeacherList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background: #f8cdd44f;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
}
.profileImgsearch {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  border-radius: 100%;
}
.marksMainContainer {
  p {
    font-weight: bold;
  }
}
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.downloadButton {
  cursor: pointer;
  .download {
    width: 50px;
    height: 50px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
  font-weight: bold;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 2px;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
}
.fileFormatContainer {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
@media (max-width: 950px) {
  .stats {
    font-size: 17px !important;
  }
  .searchBar {
    width: 220px !important;
    padding-left: 2px !important;
  }
  .list {
    width: 44% !important;
  }
  .class {
    font-size: 15px !important;
  }
  .classes {
    font-size: 15px !important;
  }
  .name {
    font-size: 15px !important;
  }
  .code {
    font-size: 15px !important;
  }
  .profile {
    margin-right: 1vw;
  }
  .electives_container {
    width: 80% !important;
  }
}
@media (max-width: 550px) {
  .header{
    flex-wrap: wrap;
    justify-content: center;
  }
  .filterWrapper{
    width: 43%;
  }
  .list {
    width: 100% !important;
    padding: 14px !important;
  }
  .name {
    font-size: 13px !important;
    // width: 170px !important;
    // height: auto !important;
  }
  .class {
    font-size: 12px !important;
    width: 170px !important;
    height: auto !important;
  }
  .classes {
    font-size: 12px !important;
  }
  .stats {
    font-size: 13px !important;
  }
  .code {
    font-size: 12px !important;
  }
  .headerFirst {
    flex-direction: column !important;
    align-items: flex-start;
  }
  .statsWrapper {
    justify-content: flex-start !important;
  }
  .heading {
    font-size: 15px !important;
  }
  .electives_container {
    width: 100% !important;
  }
  .addImage {
    height: 7vw !important;
  }
}
