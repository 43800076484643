.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
}

.category {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 15px;
  background: white;
  // border: 1px solid black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  border-radius: 10px;
  transition: all .20s ease-in-out;
  // border-radius: 20px;
}
.category:hover{
  box-shadow: 7px 7px 7px 7px  rgba(99, 99, 99, 0.2);
  scale: 1.07;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.heading {
  font-size: 30px;
  color: #990f0d;
  font-weight: bold;
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin: 0 10px;
  }
}
@media (max-width: 950px){
  .row{
    flex-wrap: wrap;
  }
  .category{
    margin: 10px 15px;
  }
  .statsWrapper{
    .stats {
    font-size: 18px !important;
    }
  }
  .heading{
    font-size: 25px;
  }
}
@media (max-width: 550px){
  .statsWrapper{
    .stats {
    font-size: 16px !important;
    }
  }
  .heading{
    font-size: 20px;
  }
}