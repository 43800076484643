.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}
.filterWrapper2 {
  margin: 0 10px;
  // padding: 1px;
  border: 1px solid black;
  border-radius: 5px;
}
filterWrapper2 Input ::placeholder {
  // outline: none;
  opacity: 5;
  font-weight: 700;
  color: rgb(52, 52, 52);
}
.filterWrapperWithLine {
  margin: 0 10px;
  // padding-right: 20px
}

.body {
  margin: 35px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    // border: 2px solid red;
    .list {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background: #f8cdd44f;
      padding: 20px;
      margin: 10px 0;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }
}

.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: 800;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .button {
    // width: 100px;
    // height: 40px;
    background: #990f0d;
    color: white;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
  }
}

.buttonBox {
  background-color: #990f0d;
  padding: 8px;
  text-align: center;
  margin-bottom: 15px;
  color: white;
}
.buttonBox button {
  font-size: 1.2rem;
  font-weight: 600;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  color: red;
  text-align: center;
  width: 10%;
}
@media (max-width: 950px) {
  .header {
    flex-wrap: wrap;
  }
  .filterWrapperWithLine {
    margin: 10px;
  }
  .body {
    margin: 35px 0;
    .lists {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      // border: 2px solid red;
      .list {
        width: 48%;
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #f8cdd44f;
        padding: 20px;
        margin: 10px 0;
        .profile {
          .profileImg {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
          }
        }
        .info {
          .name {
          }
          .class {
          }
          .number {
          }
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .header {
    flex-wrap: wrap;
  }
  .filterWrapper {
    margin: 0px;
  }
  .filterWrapperWithLine {
    margin: 10px;
    width: 100%;
  }
  .filterWrapper2 {
    width: 35%;
  }
  .buttonRow {
    .button {
      width: 90px;
    }
  }
  .body {
    margin: 35px 0;
    .lists {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      // border: 2px solid red;
      .list {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #f8cdd44f;
        padding: 20px;
        margin: 10px 0;
        .profile {
          .profileImg {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
          }
        }
        .info {
          .name {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 2px;
          }
          .class {
            font-size: 13px;
            margin-bottom: 2px;
          }
          .number {
            font-size: 13px;
            // margin-bottom: 2px;
          }
        }
      }
    }
  }
}
.RemovePlaceHolder:focus::placeholder {
  display: none;
}
.headerWrapper{
  display: flex;
  flex-wrap: wrap;
}
.headerHeading{
  font-size: 1.2rem;
  font-weight: 700;
}
