@import '../../global.scss';
.body {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url('../../assets/bg/smu.jpg');
    background-image: url('../../assets/bg/SMU_wide.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    // background-color: #b16d6b;
    background-color: #bf5f5c;
    /* Tint color */
    background-blend-mode: multiply;
    background-size: cover; 
    position: relative;
}

a {
    text-decoration: none;
    color: #b16d6b;
}

.boxContainer {
    width: 40%;
    height: 50vh;
    background-color: #f5f5f5;
    display: grid;
    grid-template-columns: 40% 40%;
    grid-template-rows: 40% 40%;
    column-gap: 10%;
    row-gap: 10%;
    padding: 1%;
    @include mobile{

    }
}

.loginBox {
    width: 100%;
    height: 100%;
    // border: 1px solid red;
    margin: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    @include mobile{
    }
}

.icon {
    width: 70px;
    padding-top: 25px;
    @include mobile{
       
    }
}

.loginText {
    margin-top: 0;
    @include mobile{
       
    }
}
.loginBox2{
    position: absolute;
    bottom: 1.5vw;
    right: 1.5vw;
    // width: 100%;
    // height: 100%;
    // border: 1px solid red;
    // margin: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.icon2{
    width: 50px;
    // padding-top: 25px;
}
.loginText2{
    font-size: 10px;
}
@media (max-width: 950px){
    .boxContainer{
        // column-gap: 15%;
        // row-gap: 5%;
        // height: 60vh;
        width: 90%;
    }
    .loginText{
        font-size: 25px;
    }
    .icon{
        width: 85px;
        padding-top: 10px;
    }
 }
 @media (max-width: 500px){
    .boxContainer{
    //     column-gap: 15%;
    //     row-gap: 5%;
    //     height: 60vh;
        width: 90%;
    }
    .loginText{
        font-size: 15px;
    }
    .icon{
        width: 55px;
        padding-top: 10px;
    }
 }