.container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 8px 0px;

}
.itemHead {
  font-size: large;
  font-weight: bold;
}
.containerItem {
  margin: 20px;
  padding: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .list {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .listItem {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .listItemKey {
        font-weight: 500;
        font-size: 1.1rem;
      }
    }
  }
}
.Modalname {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 1vw;
}
.button{
  width: 150px;
  height: 50px;
  background: #990f0d !important;
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 0 50px;
}
.containerHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
}