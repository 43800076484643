.body {
  width: 100%;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .headTitle {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .dropdown {
      width: 20%;
    }
  }
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .row {
      width: 100%;
      height: 100%;
      min-height: 50vh;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .button {
        width: 150px;
        height: 50px;
        background: #990f0d;
        color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
