.wrapper {
    width: 100%;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .headerFilterWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;
  }
  
  .filterWrapper {
    margin: 0 10px;
  }
  
  .body {
    margin: 35px 0;
    .lists {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      // border: 2px solid red;
      .list {
        width: 30%;
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #f8cdd44f;
        padding: 20px;
        margin: 10px 0;
        .profile {
          .profileImg {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            border: 1px solid black;
          }
        }
        .info {
          .name {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 2px;
          }
          .class {
            font-size: 14px;
            margin-bottom: 2px;
          }
          .number {
            font-size: 14px;
            // margin-bottom: 2px;
          }
        }
      }
    }
  }
  
  .headerFirst {
    // margin-left: auto;
    // border: 2px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 30px;
  
    .optionWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .addButton {
        cursor: pointer;
        // border: 2px solid red;
        margin: 0 10px;
        .add {
          width: 50px;
          height: 50px;
        }
      }
      .downloadButton {
        cursor: pointer;
        // border: 2px solid red;
        .download {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 5px;
    }
  }
  .loaderWrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .iconMale {
    font-size: 20px;
    display: flex;
    align-items: center;
    color: blue;
    //   justify-content: center;
  }
  .iconFemale {
    font-size: 20px;
    display: flex;
    align-items: center;
    color: red;
    margin-left: 20px;
    //   justify-content: center;
  }
  @media (max-width: 950px) {
    .header {
      width: 105%;
    }
    .stats {
      font-size: 16px !important;
    }
    .add {
      width: 8vw !important;
      height: 4vw !important;
    }
    .download {
      width: 8vw !important;
      height: 4vw !important;
    }
    .list {
      width: 44% !important;
    }
    .profile {
      margin-right: 1vw;
    }
    .name {
      font-size: 15px !important;
    }
  }
  @media (max-width: 500px) {
    .headerFirst {
      flex-direction: column;
    }
    .filterWrapper {
      margin: 0 !important;
      margin-right: 3px !important;
    }
    .add {
      width: 16vw !important;
      height: 7vw !important;
    }
    .download {
      width: 13vw !important;
      height: 7vw !important;
    }
    .header {
      margin-bottom: 2vw !important;
    }
    .list {
      width: 90% !important;
      padding: 14px !important;
    }
    .name {
      font-size: 13px !important;
    }
    .class {
      font-size: 12px !important;
    }
    .number {
      font-size: 12px !important;
    }
    .stats {
      font-size: 13px !important;
    }
  }
  
  .noResult {
    font-weight: bold;
    font-size: 20px;
    margin-top: auto;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    color: red;
  }
  