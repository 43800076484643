/* #app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  } */

/* body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */

.swiper {
  width: 100%;
  height: 50%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 50%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  /* height: 300px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 50%;
  width: 100%;
}

.mySwiper {
  /* height: 20%; */
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0;
}
.mySwiper21 {
  height: 15%;
  width: 15%;
}
.mySwiper .swiper-slide {
  width: 16% !important;
  height: 60%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 50%;
  height: 50%;
  object-fit: cover;
}
