.certificatename {
  width: 100%;
  color: #990f0d;
  font-size: 1.2rem;
  height: 1.5rem;
  // width: 160px;
  text-overflow: ellipsis;
  font-weight: 400;
}
.certificatename ::hover {
  color: #990f0d;
}
.certificate {
  width: 5vw;
}
.certificate img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mainCartSection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.certificateCart {
  padding: 1.5vw;
  // padding-bottom: 3vw;
width: 18vw;
margin: 2vw;
margin-top: 1vw;
margin-bottom: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  // width: 20%;
  box-shadow: 2px 2px 2px 2px rgb(173, 173, 173);
  transition: all 0.3s;
}
.certificateCart:hover {
  scale: 1.05;
  box-shadow: 5px 5px 5px 5px rgb(173, 173, 173);
}
.statsWrapper {
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.CartInLine{
  display: flex;
  align-items: center;
  justify-content: center;
}
