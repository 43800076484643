.upperComponent{
    // display: flex;
    // width: 100%;
    // justify-content: space-between;
}
.RightcomponentBody{
    // display:flex;   
    // justify-content: space-between;
    // width:100%;
    font-size: 1.1rem;
    flex-wrap: wrap;
}
.uppercomponentBody{
    display: flex;
    justify-content: space-around;
}
.lowercomponentBody{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.lowerSectioncomponentBody{
    display: flex;
    padding: 22px;
    padding-left: 25px;
    padding-right: 25px;
    width: 23.5%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5vw;
    border-radius: 10px 10px 5px 5px;
    transition: all .20s ease-in-out;
}
.lowerSectioncomponentBody:hover{
    box-shadow: 4px 4px 4px 4px #ccc;
    scale: 1.07;
}
.componentBody{
    display: flex;
    // box-shadow: 4px 4px 4px 4px #ccc;
    padding: 22px;
    padding-left: 25px;
    padding-right: 25px;
    width: 23.5%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5vw;
    border-radius: 10px 10px 5px 5px;
    transition: all .20s ease-in-out;
    // flex-direction: column;
}
.componentBody:hover{
    box-shadow: 4px 4px 4px 4px #ccc;
    scale: 1.07;
}
.componentBodyLeft{
    text-align: end;
    font-size: 1.4rem;
    font-weight: 600;
}
.lowerComponent{
    // display: flex;
    margin-top: 1vw;
    width: 100%;
    // justify-content: space-between;
}
.noticeBoard{
    margin-right: 1vw;
    overflow-y: scroll;
    height: 15vw;
    width: 100%;
}
.perticularNotice{
    transition: all .20s ease-in-out;
    padding: 7px;
    border-radius: 5px;
    background-color: #f5f4f4;
    margin-bottom: 1vw;
}
.perticularNotice:hover{
    scale: 1.05;
}
.lowerComponentNoticeTitle{
    font-size: .85rem;
    font-weight: 500;
}
.noticeBoard::-webkit-scrollbar{
    display: none;
}
.GraphOptions{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.noticeBoard1{
    margin-right: .5vw;
    overflow-y: scroll;
    height: 15vw;
    width: 100%;
}
.perticularNotice1:hover{
    scale: 1.05;
}
.perticularNotice1{
    transition: all .40s ease-in-out;
    padding: 7px;
    border-radius: 5px;
    background-color: #f5f4f4;
    margin-bottom: 1vw;
    display: flex;
    // justify-content:space-around ;
    align-items: center;
}
.lowerComponentNoticeTitle1{
    font-size: .85rem;
    font-weight: 500;
}
.noticeBoard1::-webkit-scrollbar{
    display: none;
}
.noticeBoardUserImage{
    width: 4vw;
    height: 3vw;
    border-radius: 100%;
    margin-right: 1vw;
}
.noticeBoardUserImage img {
    width: 100%;
    height: 100%;
}
.noticeBoardTitle{
    font-size: 1.1rem;
    background-color: #f5f4f4;
    margin-bottom: 1vw;
    font-weight: bold;
    padding :8px
}
.noticeBoard2{
    margin-right: 2vw;
    overflow-y: scroll;
    height: 15vw;
    width: 100%;
}
.perticularNotice2{
    padding: 7px;
    border-radius: 5px;
    background-color: #f5f4f4;
    margin-bottom: 1vw;
    display: flex;
    // justify-content:space-around ;
    align-items: center;
    transition: all .40s ease-in-out;

}
.perticularNotice2:hover{
    scale: 1.05;
}
.lowerComponentNoticeTitle2{
    font-size: .85rem;
    font-weight: 500;
}
.noticeBoard2::-webkit-scrollbar{
    display: none;
}
.noticeBoardUserImage2{
    width: 4vw;
    height: 3vw;
    border-radius: 100%;
    margin-right: 1vw;
}
.noticeBoardUserImage2 img {
    width: 100%;
    height: 100%;
}
.noticeBoardTitle2{
    font-size: 1.1rem;
}
.PieChartdesign{
    margin-top: 2vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.upperLeftSide{
    width: 100%;
    box-shadow: 5px 5px 5px 5px #eeeded;
    background-color: #eeeded;
    padding: 10px;
    padding-top: 15px;
    border-radius: 15px;
}
.graphUpperContainer{
    width: 100%;
    padding: 20px;
    // height: 80%;
}
.graphUpperContainer2{
    width: 30%;
    height: 270px;
}
.notice{
    width: 31%;
}
.notice1{
    width: 31%;
}
.carousel .control-dots {
    display: none !important;
}
.carousel .control-dots .dot{
    display: none !important;
}
.imageSize{
    height: 3.5vw;
    width: 3.5vw;
}
.containerData{
    display: flex;
    flex-direction: column;
}
.imageSize2{
    border: 2px solid rgb(99, 145, 236);
    padding: 5px;
    border-radius: 50%;
    height: 3vw;
    width: 3vw;
}
.imageSize3{
    border: 2px solid rgb(104, 236, 131);
    padding: 5px;
    border-radius: 50%;
    height: 3vw;
    width: 3vw;
}
.imageSize4{
    border: 2px solid rgb(144, 7, 7);
    padding: 5px;
    border-radius: 50%;
    height: 3vw;
    width: 3vw;
}
.lowerComponentDiv{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.heading{
    font-size: 1.3rem;
    font-weight: 700;
    padding: 10px;
}
@media (max-width: 550px) {
    .lowerSectioncomponentBody{
        width: 80%;
        padding: 12px;
        padding-left: 15px;
        padding-right: 15px;
    }   
    .componentBody{
        width: 80%;
        padding: 12px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .imageSize{
        height: 8.5vw;
        width: 8.5vw;
    }
    .imageSize2{
        height: 8.5vw;
        width: 8.5vw;
    }
    .imageSize3{
        height: 8.5vw;
        width: 8.5vw;
    }
    .imageSize4{
        height: 8.5vw;
        width: 8.5vw;
    }
    .componentBodyLeft{
        font-size: 1.2rem;
    }
    .graphUpperContainer2{
        width: 70%;
        margin: auto;
    }
    .lowerComponentDiv{
        flex-wrap: wrap;
    }
    .notice{
        width: 90%;
    }
    .lowerSectioncomponentBody{

    }
    .noticeBoardTitle{
        font-size: 1rem;
    }
    .noticeBoard1{
        height: 30vw;
    }
    .lowerComponentNoticeDate1{
        font-size: .8rem;
        font-weight: 700;
    }
    .noticeBoardUserImage{
        height: 11vw;
        width: 15vw;
    }
    .noticeBoardUserImage2{
        height: 11vw;
        width: 15vw;
    }
    .noticeBoard{
        height: 30vw;
    }
    .notice1{
        width: 90%;
    }
    .noticeBoard2{
        height: 32vw;
    }
    .heading{
        font-size: 1rem;
    }
}