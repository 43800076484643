#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper {
  margin-left: auto;
  margin-right: auto;
}
.mainContainer {
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  background: rgb(255, 236, 212);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  border-radius: 15px;
  margin-bottom: 30px;
}
.subContainer {
  display: flex;
}
.leftContainer {
  display: flex;
  width: 45%;
  flex-direction: column;
}
.moduleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: rgb(102, 172, 172);
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 10px;
  .moduleIcon {
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin-right: 10px;
  }
  .moduleName {
    font-weight: bold;
    font-size: 30px;
  }
}
.filterContainer {
  background: rgb(208, 244, 204);
  padding: 10px;
  margin-bottom: 13px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}
.criteriaText {
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 3px;
}
.filterSubContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterGrade {
  font-weight: 600;
  font-size: 18px;
  margin-right: 25px;
}
.dropdownContainer {
  border: 1px solid black !important;
  background: #fafafa !important;
}
.filterWrapper {
  margin-top: 5px;
  width: 35%;
}
.formatContainer {
  background: rgb(255, 244, 172);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}
.selectionContainer {
  margin-left: 20px;
  overflow-y: scroll;
  height: 330px;
  background: rgb(232, 244, 252);
  padding: 10px;
  border-radius: 10px;
  width: 55%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
  font-weight: bold;
}
.headerContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  width: 50%;
}
.btn {
  font-weight: bold;
  font-size: 18px;
  margin-right: 5px;
}
.fileFormatContainer {
  display: flex;
  font-weight: bold;
}
.asterisk {
  color: red;
  font-size: 20px;
}
.icons {
  font-size: 22px;
}
.mobileselectionContainer {
  overflow-y: scroll;
  height: 330px;
  background: rgb(232, 244, 252);
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}
.mobileformatContainer {
  background: rgb(255, 244, 172);
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}
.mobilerow {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-weight: bold;
  margin-left: 50px;
}
.mobilemoduleIcon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-right: 10px;
}
.mobilemoduleName {
  font-weight: bold;
  font-size: 20px;
}
.mobilefilterWrapper {
  margin-top: 5px;
  width: 45%;
}
.mobileheading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  width: 98%;
}
.mobilecriteriaText {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 3px;
}
.mobilefilterGrade {
  font-weight: 500;
  font-size: 17px;
  margin-right: 25px;
}
.mobilebtn {
  font-weight: 500;
  font-size: 17px;
  margin-right: 5px;
}
.mobilefileFormatContainer {
  display: flex;
  font-weight: 500;
}
