.body {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .header {
    font-size: 20px;
    font-weight: bold;
  }
  
  .horizontalContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .del {
    display: flex;
    // flex-direction: column;
    justify-content: right;
    // align-items: right;
    cursor: pointer;
  
    .delImage {
      width: 25px;
      height: 30px;
      margin-right: 10px;
      color:#990f0d
    }
  }
  
  .loaderWrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  .lists {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 65%;
    margin: 50px auto;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
    .listHeader {
      font-size: 16px;
      font-weight: 700;
      margin: 10px 0;
      padding: 0 10px;
    }
    .list:hover{
      scale: 1.03;
      box-shadow: 5px 5px 5px 5px #ccc;
    }
    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin: 10px auto;
      // border: 2px solid red;
      padding: 20px 30px;
      box-shadow: 2px 2px 2px 2px #ccc;
      transition: all .15s ease-in-out;
      border-radius: 5px;
  
      .profileImage {
        width: 200px;
        height: 150px;
      }
  
      .name {
        margin: 5px 0;
        // color: #990f0d;
        text-align: center;
        font-weight: 700;
      }
  
      .namePaid {
        color: green;
        font-weight: 700;
        margin: 5px 0;
      }
  
      .namePending {
        color: red;
        font-weight: 700;
        margin: 5px 0;
      }
  
      .value {
        font-weight: 600;
      }
  
      .valuePaid {
        color: green;
        font-weight: 600;
      }
  
      .valuePending {
        color: red;
        font-weight: 600;
      }
    }
  }
  
  .subHeading {
    font-size: 18px;
    font-weight: 800;
    margin: 10px 0;
    // padding: 0 10px;
  }
  
  .verticalContainer {
    margin: 20px 0 50px 0;
    display: flex;
    flex-wrap: wrap;
  // height: 24vw;
  overflow-y: auto;
    .receiptCard {
      width:45%;
      margin: 10px auto;
      padding: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
      .receiptCardRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // margin: 10px 0;
        // border: 2px solid red;
        padding: 10px;
        p {
          padding-top: 2px;
          padding-bottom: 2px;
          margin: 0;
        }
        .value {
          font-weight: 600;
        }
      }
    }
  }
  
  .add {
    cursor: pointer;
    display: flex;
    align-items: center;
  
    .addImage {
      width: 35px;
      height: 35px;
    }
  }
  
  .header {
    display: flex;
    justify-content: space-between;
  }
  
  .formGroup {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  
    .formLabel {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1px;
    }
  
    .formInput {
      border: 1px solid #e7ecf2;
      border-radius: 5px;
      padding: 5px;
      width: 100%;
      text-indent: 10px;
      margin-bottom: 15px;
    }
  }
  
  .formButton {
    width: 150px;
    align-self: center;
    height: 40px;
    border-radius: 5px;
    background-color: green;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 25px 30px 25px;
  }
  .fine {
    font-weight: bolder;
    font-size: 1.7rem;
    border: 3px solid black;
    padding: 5px;
    border-radius: 100%;
  }
  .newPendingContainer {
    display: flex;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    justify-content: space-between;
    padding: 15px;
    margin-top: 2vw;
  }
  .PendingFeesType {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-right: 1px solid black;
  }
  .PendingFeesTypes {
    display: flex;
    margin-bottom: 1vw;
    justify-content: space-between;
    padding: 0 4vw;
  }
  .PendingFeesType2 {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .fineSection1Heading {
    width: 10%;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
  }
  .fineSection2Heading {
    width: 29%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .fineSection1 {
    width: 10%;
    font-size: 14px;
    text-align: center;
  }
  .fineSection2 {
    width: 15%;
    text-align: center;
    font-size: 14px;
  }
  .fineSectionDes {
    width: 35%;
    text-align: center;
    font-size: 14px;
    margin: 10px;
  }
  .fineSectionDesHeading {
    font-weight: bold;
    width: 35%;
    text-align: center;
    font-size: 14px;
    margin: 10px;
  }
  .headerSectionModal {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding-bottom: 2vw;
    border-bottom: 1px solid black;
  }
  .add1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .addStyling {
    width: 3vw;
    height: 3vw;
  }
  .addImage {
    width: 100%;
    height: 100%;
  }
  .addField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;
  }
  
  .modalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
  }
  .penaltyDataOverflow {
    height: 20vw;
    overflow-y: scroll;
  }
  .penaltyDataOverflow::-webkit-scrollbar {
    display: none;
  }
  .headerSectionModal2 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding-bottom: 1vw;
    padding-top: 1vw;
    border-bottom: 1px solid black;
  }
  .fineSectionIcon {
    width: 16px;
  }
  .fineSectionIcon:hover {
    cursor: pointer;
  }
  .fineSection3 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // padding: 0 2vw;
  }
  .fessFormetdesign {
    font-weight: 800;
    color: red;
    text-align: right;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .heading {
    font-weight: 700;
    font-size: 17px;
  }
  .customModalContent {
    max-width: 1000px !important;
    width: 100% !important;
  }
  .noResult {
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    color: red;
    text-align: center;
  }
  .asterisk {
    color: red;
    font-size: 16px;
  }
  .fessFormetdesign{
      font-weight: 800;
      color: red;
  }
  
  .container1{
    display: flex;
    justify-content: space-around;
    // align-items: center;
  }
  .profileDescription{
    padding: 15px;
    box-shadow: 5px 5px 5px 5px  rgba(99, 99, 99, 0.2);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    width: 30%;
    background-color:rgb(243,225,243);
  }
  // .paidContainer{
  // width: 50%;
  // }
  .profileImg {
    width: 85px;
    padding: 2px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid black;
  }
  .feeImages{
    border: 2px solid rgb(99, 145, 236);
    padding: 5px;
    border-radius: 50%;
    height: 2.5vw;
    width: 2.5vw;
  }
  .PaidFee{
   display: flex;
      padding: 15px;
      padding-left: 25px;
      padding-right: 25px;
      width: 30%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5vw;
      border-radius: 10px 10px 5px 5px;
      transition: all .20s ease-in-out;
      box-shadow: 2px 2px 2px 2px #ccc
  }
  .PaidFee:hover{
    box-shadow: 4px 4px 4px 4px #ccc;
    scale: 1.06;
  }
  .paidUpper{
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .paidFeeConatiner{
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }
  .newContainer{
    display: flex;
    flex-direction: row-reverse;
  }
  .newReciptContainer{
    width: 100%;
  }
  .containerFontSize{
    font-size: 16px;
    font-weight: 600;
  }
  .containerFontSize2{
    font-size: 14px;
    font-weight: 600;
  }
  @media (max-width:550px){
    .subHeading{
      font-size: 15px;
    }
      .header{
          align-items: center;
          font-size: 13px;
      }
      .fine{
          font-size: 1rem;
      }
      .fessFormetdesign{
        margin-top: 7px;
          font-size: 13px;
      }
      .PendingFeesTypes{
          font-size: 13px;
      }
      .horizontalContainer{
          flex-direction: column;
      }
      .lists{
          width: 100%;
          margin: 15px auto;
      }
      .verticalContainer {
        margin: 20px 0 50px 0;
        display: flex;
        flex-wrap: wrap;
      // height: 24vw;
      overflow-y: auto;
        .receiptCard {
          width:90%;
          margin: 10px auto;
          padding: 10px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      
          .receiptCardRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            // margin: 10px 0;
            // border: 2px solid red;
            padding: 10px;
            p {
              padding-top: 2px;
              padding-bottom: 2px;
              margin: 0;
            }
            .value {
              font-weight: 600;
            }
          }
        }
      }
      .container1{
        flex-direction: column;
      }
      .profileDescription{
        width: initial;
        margin-bottom: 3vw;
      }
      .paidContainer{
        width: initial;
      }
      .PaidFee{
        width: 90% !important;
        margin-bottom: 2vw;
      }
      .profileImg{
        width: 80px;
        height: 80px;
      }
      .containerFontSize{
        font-size: 15px;
      }
      .containerFontSize2{
        font-size: 14px;
      }
      .paidUpper{
        flex-direction: column;
        align-items: center;
      }
      .feeImages{
        width: 8vw;
        height: 8vw;
      }
      .heading{
        font-size: 17px;
      }
      .newContainer{
        flex-direction: column;
      }
      .newReciptContainer{
        width: initial;
      }
  }