.headerLogo{
    width: 6.5vw
}
@media (max-width: 1000px){
    .headerLogo{
        width: 9.5vw
    }
}
@media (max-width: 750px){
    .headerLogo{
        width: 13vw
    }
}
