.selected {
  position: relative;
}
.selected::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  animation: flowAnimation 0.5s linear forwards;
}
@keyframes flowAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  color: black;
}
.fontSize {
  font-weight: 700;
  font-size: 13px;
}
.fontSize p {
  white-space: pre-line;
}
.fontSizeTitle {
  font-weight: 700;
  font-size: 18px;
}
.fontSizeDes {
  font-weight: 500;
}
.profileContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}
.date {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  color: darkslategrey;
}
.list {
  margin: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  background: #fff;
  width: 90%;
  transition: all 0.6s ease-in-out;
}
.listWithoutRead {
  margin: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  background: #eee;
  width: 90%;
}
.list:hover {
  scale: 1.04;
}
.iconImages {
  width: 41px;
  height: 40px;
  color: #b92b2b;
}
.notificationHeading {
  font-weight: bold;
  padding: 10px;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  margin-top: 10px;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: 50px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  width: 90%;
  color: red;
  text-align: center;
}

// .notificationHeading:hover::after {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   width: 100%;
//   background: rgba(0, 0, 0, 0.1);
//   border-radius: 20px;
//   animation: flowAnimation 0.5s linear forwards;
// }

// @keyframes flowAnimation {
//   from {
//     width: 0;
//   }
//   to {
//     width: 100%;
//   }
// }
