.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.filterWrapper {
  margin: 0 10px;
}

.body {
  margin: 50px 0;
}

.lists {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.list {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  min-height: 260px;
}
.list:hover {
  box-shadow: #990f0d 0px 2px 8px 0px;
}
.profileImage {
  width: 230px;
  height: 180px;
  border: 2px solid black;
}

.name {
  margin: 5px 0;
  color: #990f0d;
  text-align: center;
  width: 220px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 50px;
}

.class {
  margin: 5px 0 10px 0;
}

.addButton {
  cursor: pointer;
  // border: 2px solid red;
  margin: 0 10px;
  .add {
    width: 50px;
    height: 50px;
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.headerFirst {
  // margin-left: auto;
  // border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 30px;
  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      // border: 2px solid red;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      // border: 2px solid red;
      .download {
        width: 35px;
        height: 35px;
      }
    }
    .migrateButton {
      cursor: pointer;
      // border: 2px solid red;
      .migrate {
        font-size: 28px;
        margin-left: 15px;
      }
    }
  }
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.errorContainer {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorSubContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  background: rgb(240, 7, 19);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 15px;
  padding: 20px;
}
.errorCodeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.code4 {
  font-size: 30px;
  font-weight: bold;
  margin-right: 2px;
  margin-left: 2px;
}
.errorText {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
.errorText2 {
  color: white;
  font-weight: bold;
  margin: 5px;
}
.readMoreButton {
  text-align: center;
}
.eyeIcon {
  font-size: 20px;
  margin-right: 10px;
}
.views {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  font-style: italic;
}
@media (max-width: 950px) {
  .header {
    width: 50%;
    margin-top: 10px;
  }
  .stats {
    font-size: 17px !important;
  }
}
@media (max-width: 500px) {
  .header {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
  .headerFirst {
    flex-direction: column;
    align-items: flex-start;
  }
}
