.calenderWidth {
  overflow-y: auto;
  width: 40%;
  max-height: 300px;
  border: 2px solid black;
}
.fc .fc-scroller-liquid-absolute {
  position: relative !important;
  overflow: auto;
}
.headerFilterWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.WholeContainer {
  display: flex;
  justify-content: space-between;
}
.BoxContainer {
  display: flex;
  width: 55%;
  margin-left: 4vw;
  flex-direction: column;
}
.AttendenceButton {
  background: #990f0d !important;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.calenderWidth {
  overflow-y: auto;
  width: 40%;
  max-height: 320px;
  height: fit-content;
  border: 2px solid black;
}
.fc .fc-scroller-liquid-absolute {
  position: relative !important;
  overflow: auto;
}
.headerFilterWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.WholeContainer {
  display: flex;
  justify-content: space-between;
}
.AttendenceButton {
  // width: 100px;
  // height: 40px;
  background: #990f0d !important;
  // border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.ButtonContainer {
  margin-top: 8vw;
  width: 100%;
  // margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.headerFilterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
}

.filterWrapper {
  margin: 0 10px;
}

.profileImg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 3vw;
}

.headerFirst {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .optionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .addButton {
      cursor: pointer;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}
.downloadButton {
  cursor: pointer;
  .download {
    width: 50px;
    height: 50px;
  }
}
.ButtonContainer {
  margin-top: 8vw;
  width: 100%;
  // margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected {
  appearance: none;
  background-color: #fafafa;
  font-weight: bold;
  // padding: 6px 12px;
  font-size: 1em;
  line-height: 1.25;
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  // border: 1px solid lightgray;
  border-radius: 5px;
  // border-bottom: 2px solid #000; /* Change the border color here */
}

.selected:focus {
  border: 2px solid lightgray;
  border-radius: 5px;
}
.todayButton {
  background-color: #cd3dcd;
  color: white;
  padding: 6px;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 10px;
}
.to .wrapper {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.headerFilterWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.filterWrapper {
  margin: 0 10px;
}

.body {
  margin: 35px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .list {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: rgba(248, 205, 212, 0.31);
      box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
      padding: 20px;
      margin: 10px 0;

      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
    .notFound {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
      background: rgb(222, 235, 249);
      box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
      padding: 20px;
      margin: 10px 0;
      border-radius: 5px;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
    .notFound {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
      background: rgb(222, 235, 249);
      box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
      padding: 20px;
      margin: 10px 0;
      border-radius: 5px;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          border: 1px solid black;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;
          // margin-bottom: 2px;
        }
      }
    }
  }

  .headerFirst {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .optionWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .stats {
        font-weight: bold;
        font-size: 20px;
        margin: 0 5px;
      }
    }
    .loaderWrapper {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
    }
    .iconMale {
      font-size: 20px;
      display: flex;
      align-items: center;
      color: blue;
      //   justify-content: center;
    }
    .iconFemale {
      font-size: 20px;
      display: flex;
      align-items: center;
      color: red;
      margin-left: 20px;
      //   justify-content: center;
    }
    .profile {
      display: flex;
      align-items: center;
      // justify-content: space-around;
    }
    .attendanceDetails {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      // text-align: left;
    }
    .studentName {
      font-size: 1.1rem;
    }
    .addButton {
      cursor: pointer;
      margin: 0 10px;
      .add {
        width: 50px;
        height: 50px;
      }
    }
    .downloadButton {
      cursor: pointer;
      .download {
        width: 35px;
        height: 35px;
      }
    }
  }
}
.statsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  .stats {
    font-weight: bold;
    font-size: 20px;
    margin: 0 5px;
  }
}
.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.iconMale {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: blue;
  //   justify-content: center;
}
.iconFemale {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: red;
  margin-left: 20px;
  //   justify-content: center;
}

.add {
  width: 50px;
  height: 50px;
}

.academicYear {
  cursor: pointer;
  background-color: #fdc1a5;
  border-radius: 60%;
  margin: 0 10px;
  position: fixed;
  right: 20px;
  top: 93%;
  transform: translateY(-50%);
  padding: 10px;

  .add {
    width: 50px;
    height: 50px;
  }
}
.profile {
  display: flex;
  align-items: center;
  // justify-content: space-around;
}
.profileModal {
  display: flex;
  align-items: center;
  width: 37%;
  height: 100px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding-left: 7px;
}
.attendanceDetails {
  margin-left: 70px;
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
}
.studentName {
  font-size: 1.1rem;
}
.heading {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.selected-date {
  background-color: #4e13c8;
}
.modalCalendar {
  overflow-y: auto;
  width: 60%;
  max-height: 360px;
  border: 2px solid black;
}
.customModalContent {
  max-width: 1000px !important; /* Set the desired maximum width */
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  max-height: 90vh;
}

.modalProfileImg {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 3vw;
  border: 1px solid black;
}
.noResult {
  font-weight: bold;
  font-size: 20px;
  margin-top: auto;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
.absentHoverContainer {
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 20%;
  padding: 15px;
  width: 300px;
  z-index: 9;
  max-height: 300px;
  overflow-y: scroll;
}
.halfDayHoverContainer {
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 75%;
  padding: 15px;
  width: 300px;
  z-index: 9;
  max-height: 300px;
  overflow-y: scroll;
}
.arrows {
  font-size: 30px;
}
.calendarHeaderYear {
  padding-left: 8px;
  font-size: 1.5em;
}
.headerValSel {
  font-size: 1.5em;
}
.headerVal {
  font-size: 1em;
}
.calendarHeaderMonth {
  padding-left: 20px;
  font-size: 1.5em;
}
.selectGrade {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  background-color: rgb(234, 60, 83);
  width: 180px;
  color: white;
  padding: 10px;
  font-weight: bold;
}
.dateContainer {
  display: flex;
  width: 50%;
  font-weight: bold;
}
.attendanceData {
  display: flex;
  justify-content: space-between;
}
.updateHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addAttendanceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
  font-weight: bold;
}
.headerContainer {
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgba(248, 205, 212, 0.3098039216);
  padding: 2px;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
}
.fileFormatContainer {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
@media (max-width: 500px) {
  .WholeContainer {
    flex-direction: column;
  }
  .calenderWidth {
    width: 93%;
  }
  .arrows {
    font-size: 20px;
  }
  .calendarHeaderYear {
    padding-left: 4px;
    font-size: 1em;
  }
  .headerVal {
    font-size: 0.8em;
  }
  .headerValSel {
    font-size: 0.8em;
  }
  .calendarHeaderMonth {
    padding-left: 10px;
    font-size: 1em;
  }
  .todayButton {
    padding: 4px;
    border-radius: 5px;
    font-weight: 600;
    margin-right: 5px;
  }
  .BoxContainer {
    width: 100%;
    margin-left: 0;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .dateContainer {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .attendanceData {
    flex-direction: column;
  }
  .body {
    .lists {
      .list {
        width: 100%;
        padding: 10px;
      }
    }
  }
  .updateHeadingContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .addAttendanceContainer {
    flex-direction: column;
    align-items: end;
  }
  .headerFirst {
    flex-direction: column;
    align-items: flex-start;
  }
  .profileModal {
    width: 100%;
    margin-bottom: 8px;
  }
  .selectGrade {
    font-weight: 600;
    margin-top: 30px;
    padding: 8px;
  }
  .name {
    font-size: 13px !important;
  }
}
