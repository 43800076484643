@import url("../../../Styles/color.css");

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
  margin-right: 10px;
}

.body {
  margin: 50px 0;
  .lists {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .list {
      width: 30%;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
      margin: 10px 0;
      .profile {
        .profileImg {
          width: 80px;
          height: 80px;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      .info {
        .name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        .class {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .number {
          font-size: 14px;

        }
      }
    }
  }
}

.wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.forWidthLeft {
  width: 40%;
}
.forWidthRight {
  width: 55%;
  padding-top: 5px;
}
.stepHead {
  display: flex;
  align-items: baseline;
}
.stepHead:hover {
  cursor: pointer;
}
.helpmenu {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 16px;
  width: 100%;
  bottom: 10px;
}
.clicked {
  background-color: #eee;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  padding: 6px;
  border-radius: 5px;
}
.stepNo {
  background-color: #d3f5f0;
  border-radius: 50%;
  width: 26px;
  height: 25px;
  text-align: center;
  font-weight: bold;
}
.stepTitle {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
}
.des {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.image {
  height: 100px;
  width: 100px;
}
.forMargin {
  margin-bottom: 2px;
  padding: 5px;
  width: 100%;
}
@media (max-width: 950px) {
  .stepTitle {
    font-weight: 700;
    font-size: 16px !important;
  }
  .list {
    width: 44% !important;
  }
  .name {
    font-size: 15px !important;
  }
}
@media (max-width: 500px) {
  .list {
    width: 90% !important;
    padding: 14px !important;
  }
  .name {
    font-size: 13px !important;
  }
  .stepTitle {
    font-weight: 500;
    font-size: 14px !important;
  }
}
