.body {
  width: 100%;
  min-height: 65vh;
  position: relative;
}
.cards {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    height: 10px;
  }
  .card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: inline-block;
    text-align: center;
    width: 500px;
    background: white;
    min-height: 65vh;
    border-radius: 10px;
    padding: 20px 0;
    margin: 0 10px;

    .header {
      width: 80%;
      margin: 0 auto;
      border-bottom: 2px solid #990f0d;

      .title {
        font-size: 25px;
        font-weight: 600;
      }
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;

      .contentItem {
        width: 80%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .name {
            font-size: 18px;
            font-weight: 800;
          }
          .class {
            font-size: 16px;
          }
        }
        .right {
          cursor: pointer;
        }
      }
    }
  }
}
.headerFirst {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .statsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .stats {
      font-weight: bold;
      font-size: 20px;
      margin: 0 10px;
    }
  }
}
