.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.conductCerticateWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64%;
    margin-left: 1.1vw;
}
.conductCerticateWrapperA5{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 54%;
  margin-left: .9vw;
}
.ConductcertiHeading {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 700;
  margin-top: 0.5vw;
  font-family: sans;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}
.ConductcertiHeadingA5{
  font-size: .9rem;
  text-align: center;
  font-weight: 700;
  margin-top: 0.5vw;
  font-family: sans;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}

.HeadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
}
.conductData{
    margin-top: 1vw;
    font-size: .9rem;
    font-family: sans;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    // text-align: ;
}
.conductDataA5{
  margin-top: 1vw;
    font-size: .8rem;
    font-family: sans;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    // text-align: justify;
}
.dynaicData{
    font-weight: bold;
    font-size: .9rem;
}
.dynaicDataA5{
    font-weight: bold;
    font-size: .8rem;
}
.principalSign{
    text-align: end;

}
.downloadButoon{
    margin-top: 3vw;
    background-color: green !important;
    color: white;
    border-radius: 4px;
    padding: 5px 8px 5px 8px;
    font-weight: bold;
    margin-left: 8px;
  }
  .inputFieldTag{
    border: 2px solid black;
    border-radius: 5px;
  }